import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import MainMenu from './components/Menus/Menu'
import logo from './assets/img/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider, useSnackbar } from 'notistack';
import AppRoutes from 'AppRoutes';
import { BrowserRouter as Router , useLocation, useSearchParams} from 'react-router-dom'

import Login from 'components/Login/Login';
import Cookies from 'universal-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import LinkUserToWorkstation from 'elements/LinkUserToWorkstation';
import AccountMenu from 'components/Account/AccountMenu';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Message from 'elements/Message';
import { Badge, Button } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import useIdleLogout from './SessionHook'; 
import { ApiRequest } from 'GlobalFunctions';


const cookies = new Cookies();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins', 
      'sans-serif'
    ].join(','),
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 300,
    fontWeightBold: 700,
  },
  palette: { 
    primary: {
      light: '#000',
      main: '#1D3B47',
      dark: '#1D3B47',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f04446',
      main: '#f04446',
      dark: '#f04446',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    background: {
      default: '#fafafa',
    },
  },
  

});






const Site = () => {
  const childRef = useRef();
  //const userIsLogged = cookies.get('ltkn') !== undefined;
  const userIsLogged = localStorage.getItem('ltkn') !== null;
  /*=======================================
  =======================================*/


  /*=======================================
    TRY TO GET INVITATION PARAMS
  =======================================*/
  const [searchParams, setSearchParams] = useSearchParams();
  const invitation = searchParams.get("invitation")
  const email = searchParams.get("email")
  const workstation_code = searchParams.get("workstation_code")


  const [invitationParams, setInvitationParams] = useState({});


  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const isHome =location.pathname === "/"? "home" : "";



  /*=======================================
  FIREBASE CONFIG
  =======================================*/
  const firebaseConfig = {
    apiKey: "AIzaSyAzYHL1F4w33AUVn6rSOuguU9jiJ7_U6S0",
    authDomain: "connect-3s-a305c.firebaseapp.com",
    projectId: "connect-3s-a305c",
    storageBucket: "connect-3s-a305c.appspot.com",
    messagingSenderId: "745675452724",
    appId: "1:745675452724:web:4a92a9a9498fc674203561"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  /*=======================================*/

  //USE FIREBASE MESSAGING
  const messaging = getMessaging(app);
  getToken(messaging, { vapidKey: 'BAMuY_Dy53sG68qq0sWr5tjdFTk9l6X0WMlM3pJi9B9po2LG41dqF3dcHJfyTl3_1Ulph5ovxhWMasMb7KKTZtc' }).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });



  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    toast(<Message notification={payload.notification} />);
  });

    // useEffect(() => {
    //   toast(<Message notification={{title: "Bienvenido", body: "Bienvenido a Connect 3S"}} />);
    // }, []);

  /*=======================================
  SESSION TIMEOUT
  =========================================*/
  const handleLogout = useCallback(() => {
    localStorage.removeItem('ltkn');
    localStorage.removeItem('loginTime');
    window.location.reload();
  }, []);
  
  const checkSessionTimeout = (sessionDuration: number) => {
    const loginTime = localStorage.getItem('loginTime');
    if (loginTime) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(loginTime, 10) > sessionDuration) {
        handleLogout();
      }
    }
  };
  
  const sessionDuration = 3600000;
  useIdleLogout(handleLogout, sessionDuration); // 1 hour
  
  useEffect(() => {
    // Check session timeout on page load
    checkSessionTimeout(sessionDuration);
  
    // Store login time if not already stored
    if (!localStorage.getItem('loginTime')) {
      localStorage.setItem('loginTime', new Date().getTime().toString());
    }
  }, [sessionDuration, handleLogout]);
  /*=======================================*/

  useEffect(() => {
    if(localStorage.getItem('ltkn')){
      getPermissions()
    }
  }, [location]);



  /*=======================================
  GET PERMISSIONS IF USER IS LOGGED
  =======================================*/
  const [permissions, setPermissions] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [userName, setUserName] = useState('');
  const [userLastname, setUserLastname] = useState('');

  useEffect(() => {
    if(userIsLogged){
      getPermissions();
    }
  }, [userIsLogged]);
      
  const getPermissions = async () => {
    setLoading(true);
    await ApiRequest({
      url: "/users/permissions",
      method: "get",
      headers: {
          ltkn: localStorage.getItem('ltkn')
      },
      query: {
      }, 
      setResponse: (response: any) => {
        if(response.status === "success"){
          console.log(response);
          setPermissions(response.routes);
          setCompanyName(response.company_name);
          setUserName(response.user_name);
          setUserLastname(response.user_last_name);

          setLoading(false);
        }
        else{
          localStorage.removeItem('ltkn');
          window.location.reload();
        }
      },
    });
  }


  return (
    <ThemeProvider theme={theme} >
      <SnackbarProvider maxSnack={3}>
        {!userIsLogged? (
          <Login setLoading={setLoading} />
        ) : (
          <>
          {localStorage.getItem('delegated_company_id') && 
          <div className='delegated_company'>
            Estás trabajando en la empresa: {localStorage.getItem('delegated_company_name')}
            <Button   
              variant="contained"
              onClick={() => {
                localStorage.removeItem('delegated_company_id');
                localStorage.removeItem('delegated_company_name');
                window.location.href = "/companies"
              }}
            >Salir</Button>
          </div>
          }
          <div id='structure'>
            <aside id="menu">
              <div id='logo'>
                <a href='/'>
                  <img src={logo}></img>
                  
                </a>
                
              </div>
              <MainMenu permissions={permissions} />
              
            </aside>
            <div id="main-container"> 
              <div id='top_bar'>
                  <div id='top_bar_left'>
                    <span className='top_company_name'>{companyName}</span>
                  </div>
                  <div id='top_bar_right'>
                  {/* <Badge color="secondary" badgeContent={5} max={999} >
                    <NotificationsNoneOutlinedIcon style={{color: 'white', fontSize: '1.7rem'}} />
                  </Badge> */}

                  <div className='user_info'>
                    <span className='user_name'>{userName} {userLastname}</span>
                  </div>

                    <AccountMenu />
                  </div>
              </div>
              <div id="main-content">
              <div id='loader' className={!loading? 'loaderHide' : ''}>
                <CircularProgress />
              </div>
                <AppRoutes setLoading={setLoading} permissions={permissions} />
              </div>
            </div>
          </div>
          <ToastContainer />
          </>
        )}
      </SnackbarProvider>
    </ThemeProvider>
  );
  
}

// ========================================
 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <Router>
      <Site />
    </Router>
);







