import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { colors, LinearProgress } from '@mui/material';
import AutocompleteField from 'elements/AutocompleteField';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { useEffect, useState } from 'react';
import { ApiRequest } from 'GlobalFunctions';

export default function SupplierProducts(props: any) {



  const [formValues, setFormValues] = useState({
    suppliers: [],
  });

  const [data, setData] = useState([] as any);

  const getSupplierProducts = async () => {

    // axios.get(ProjectConfig.api_url+"/dashboard/getSupplierProducts",
    // {
    //   headers: {
    //     'ltkn': localStorage.getItem('ltkn') 
    //   },
    //   params: {
    //     suppliers: formValues.suppliers
    //   }
    // })
    // .then((response) => {
    //     console.log("suppliers data ",response.data); 
    //     setData(response.data);
    // })
    // .catch((err) => {
    //   console.log(err.response.data);
    // })
    // .finally(() => {
    // })
    await ApiRequest({
      url: "/dashboard/getSupplierProducts",
      method: "get",
      headers: {
        'ltkn': localStorage.getItem('ltkn') 
      },
      query: {
        suppliers: formValues.suppliers
      },
      setResponse: (response: any) => {
        setData(response)
      }
    })
  }

  useEffect(() => {
    getSupplierProducts();
  }, [formValues]);


  return (
    <div className="supplierProducts">
      <AutocompleteField 
        label="Proveedores"
        endpoint="/suppliers/list"
        multiple={true}
        value={formValues.suppliers}
        onChange={(data: any) => {
            setFormValues({...formValues, suppliers: data ? data : []});
        }}
      />


      {data.map((item: any, index: any) => (
        <div className="supplierProducts_indicator" key={index}>
          <div className="supplierProducts_bar">
            <LinearProgress variant="determinate" value={item.percentage} sx={{ height: 10, borderRadius: 5, '& .MuiLinearProgress-bar1Determinate': { backgroundColor: item.color } }} />
            <span className="supplierProducts_bar_text">{item.supplier_name}</span>
          </div>
          <div className="supplierProducts_indicator_text">
            <div className='supplierProducts_indicator_quantity'>{item.supplier_products}</div>
            <div className='supplierProducts_indicator_price'>${item.price.toLocaleString()}</div>
          </div>
        </div>
      ))}
    </div>

    
  );
}


