import axios from "axios";
import { useEffect, useState } from "react";
import Box from "elements/Box";
import { Button, Chip, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ProjectConfig } from "Global";
import AlertDialog from "elements/AlertDialog";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useSnackbar } from "notistack";

import "assets/css/main.css";

import MovementEditor from "./MovementEditor";
import Filters from "elements/Filters/Filters";
import FiltersApplied from "elements/Filters/FiltersApplied";
import TuneIcon from '@mui/icons-material/Tune';
import { ApiRequest } from "GlobalFunctions";

export default function MovementList(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const currentPage = parseInt(localStorage.getItem("movementPage") || "1");
  const [page, setPage] = useState(currentPage);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);

  /*=========================================================
  CONCEPTS
  =========================================================*/
  const [concepts, setConcepts] = useState<any[]>([]);
  const getMovementsConcepts = async () => {
    await ApiRequest({
      url: "/inventorymovements/list_concept",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        page: page,
      },
      setResponse: (response: any) => {
        setConcepts(response.list);
      },
    });
  }

  /*=========================================================
  FILTERS
  =========================================================*/
  const [open, setOpen] = useState(false);

  const filters = [
    {type: "text", label: "ID", name: "company_movement_id", value: ""},
    {type: "select", label: "Concepto", name: "inventory_movements.concept_id", value: "", options: 
      concepts.map((concept: any) => {
        return {label: concept.concept, value: concept.concept_id}
      })
    },
  ]
  
  const [filtersValues, setFiltersValues] = useState<any>([]);

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
    //save in localstorage
    localStorage.setItem('filters', JSON.stringify(tempFilters));
  }
  /*=========================================================*/

  useEffect(() => {
    setLoading(true);
    loadList();
    getMovementsConcepts();
  }, [page,filtersValues ]);

  
  

  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: "/inventorymovements/list",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        page: page,
        filters: filtersValues,
      },
      setResponse: (response: any) => {
        setList(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      },
    });

    localStorage.setItem("movementPage", page.toString());
  };

  const handleDelete = (movement_id: number) => {
    setActiveItem(movement_id);
    setOpenAlert(true);
  };

  const deleteItem = () => {
    const body = JSON.stringify({
      movement_id: activeItem,
    });

    axios
      .post(ProjectConfig.api_url + "/inventorymovements/deleteItem", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          enqueueSnackbar("Movimiento eliminado con éxito", { variant: "success" });
          loadList();
        }
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setOpenAlert(false);
      });
  };




  return (
    <div id="InventoryMovementList" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Movimientos de Inventario</h2>
            <span className="h2_subtitle">Lista de movimientos de inventario</span>
          </div>
          <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveItem(null);
                setOpenEditor(true);
              }}
              startIcon={<AddIcon />}
            >
              Crear movimiento
            </Button>

            
          </div>
        </div>

        

        <IconButton
                color="inherit"
                aria-label="menu"
                onClick={() => setOpen(true)}
              >
              <TuneIcon />
          </IconButton>
            <Filters open={open} setOpen={setOpen} filters={filters} setFilters={setFilters}/>
            <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues}/>
            
        <Box id="InventoryMovementListIn" className="box100">
          <TableContainer>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>ID</TableCell>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>Usuario / Almacen</TableCell>
                  
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>Comentarios</TableCell>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>Tipo de movimiento</TableCell>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>Fecha</TableCell>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>Plataforma</TableCell>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, i) => (
                  <TableRow key={i} hover role="checkbox">
                    <TableCell>{item.company_movement_id}</TableCell>
                    <TableCell>
                      <span className='flex_title'>{item.name} {item.last_name}</span>
                      <span className='flex_subtitle'>{item.warehouse_name}</span>
                    </TableCell>
                    <TableCell>{item.comments}</TableCell>
                    <TableCell>{item.movement_type} - {item.concept}</TableCell>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>
                      <Chip size="small" label={item.platform} color={item.platform === "Web" ? "primary" : "secondary"} />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        startIcon={<ModeEditIcon />}
                        onClick={() => {
                          setActiveItem(item.movement_id);
                          setOpenEditor(true);
                        }}
                      >
                        Editar
                      </Button>
                      {(localStorage.getItem('role_id') === "1" || localStorage.getItem('role_id') === "2") &&
                      <IconButton aria-label="delete" color="primary" onClick={() => handleDelete(item.movement_id)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
            style={{ marginTop: "20px", alignSelf: "center" }}
          />
        </Box>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="¿Está seguro que desea eliminar este movimiento?"
        send={deleteItem}
      />

      <MovementEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        movement_id={activeItem}
        loadList={loadList}
      />
    </div>
  );
}
