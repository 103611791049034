import Inventory from 'components/Dashboard/Inventory/Inventory';
import Debt from 'components/Dashboard/Debt/Debt';

export default function Dashboard(props: any) {
    const { setLoading } = props;
    return (
        <>
            <Inventory setLoading={setLoading} />
            <Debt setLoading={setLoading} />
        </>
    );
}