import React, { ReactNode, useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';

/* INTERFACES */
interface DateRangeValueProp {
    startDate: Date | null;
    endDate: Date | null;
}

/* Types */
type DateRangeVariant = 'two-steps' | 'side-by-side';

/**
 * DateRange
 *  - This component renders a two-step date range picker
 * @param props 
 * @returns 
 */
export default function DateRange ( props: any ) {

    const {
        label = '',
        placeholder = '',
        value = {} as DateRangeValueProp,
        format = 'MM/dd/yyyy',
        onChange = () => {},
        disabled = false,
        required = false,
        error = false,
        helperText = '',
        fullWidth = false,
        inputProps = {},
        InputProps = {
            endAdornment: (
                <EventIcon />
            ),
        },
        InputLabelProps = {},
        variant = 'standard',
        size = 'medium',
        color = 'primary',
        className = '',
        style = {},
        sx = {},
        variantDatePicker = 'two-steps' as DateRangeVariant,
        ...otherProps
    } = props;

    const [isOpenStartDatePicker, setIsOpenStartDatePicker] = useState(false);
    const [isOpenEndDatePicker, setIsOpenEndDatePicker] = useState(false);

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [dateRangeTextValue, setDateRangeTextValue] = useState<string>('');

    useEffect(() => {
        if (startDate && endDate) {
            const start = moment(startDate).format(format);
            const end = moment(endDate).format(format);
            setDateRangeTextValue(`${start} - ${end}`);
        } else {
            // show empty format if startDate or endDate is null
            setDateRangeTextValue(`${format} - ${format}`);
        }
    }, [startDate, endDate]);

    /* VALIDATE */
    useEffect(() => {
        if (checkIsDateRange(value)) {
            setStartDate(value.startDate);
            setEndDate(value.endDate);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    }, [value]);

    
    const checkIsDate = (date: any) => {
        return date && new Date(date).toString() !== 'Invalid Date';
    };

    const checkIsDateRange = (dateRange: DateRangeValueProp) => {
        let isValid = false;

        if (!dateRange)
            return isValid;

        if (!dateRange.startDate)
            return isValid;

        if (!dateRange.endDate)
            return isValid;

        if (!checkIsDate(dateRange.startDate))
            return isValid;
        
        if (!checkIsDate(dateRange.endDate))
            return isValid;

        if (dateRange.startDate > dateRange.endDate)
            return isValid;

        isValid = true;
        return isValid;
    };

    /* HANDLE CONFIRM AND CANCEL */
    const handleCancel = () => {
        setStartDate(null);
        setEndDate(null);
    };

    const handleConfirm = () => {
        const dataRange = {
            startDate,
            endDate,
        };
        if (checkIsDateRange(dataRange) && startDate && endDate) {
            onChange({
                startDate: new Date(startDate),
                endDate: new Date(endDate),
            });
        } else {
            handleCancel();
        }
    };

    /* RENDER */

    function renderStartDatePicker(is_two_steps_variant: boolean): ReactNode {
        return (
            <StaticDatePicker
                value={startDate}
                onChange={(date: any) => {
                    if (checkIsDate(date)) {
                        setStartDate(date);
                        
                        if (is_two_steps_variant) {
                            setIsOpenStartDatePicker(false);
                            setIsOpenEndDatePicker(true);
                        }
                    }
                }}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        autoFocus
                        margin="normal"
                        variant="standard"
                        helperText="Choose start date"
                    />
                )}
                showToolbar={false}
                componentsProps={{
                    actionBar: {
                        actions: []
                    }
                }}
            />
        );
    };

    const renderEndDatePicker = (is_two_steps_variant: boolean) => {
        return (
            <StaticDatePicker
                value={endDate}
                onChange={(date: any) => {
                    if (checkIsDate(date)) {
                        setEndDate(date);

                        if (is_two_steps_variant) {
                            setIsOpenEndDatePicker(false);
                            handleConfirm();
                        }
                    }
                }}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        autoFocus
                        margin="normal"
                        variant="standard"
                        helperText="Choose end date"
                    />
                )}
                showToolbar={false}
                componentsProps={{
                    actionBar: {
                        actions: []
                    }
                }}

                {...(
                    (checkIsDate(startDate)) ?
                    { minDate: moment(startDate) } :
                    {}
                )}
            />
        );
    };


    return (
        <>
            {/* Textfield: show the date-range value and onClick dialog the staticDatePicker for choose start date limit of dateRange */}
            <TextField
                id="date"
                className={className}
                label={label}
                placeholder={placeholder}
                value={dateRangeTextValue}
                fullWidth={fullWidth}
                variant={variant}
                size={size}
                color={color}
                style={style}
                sx={sx}
                disabled={disabled}
                required={required}
                error={error}
                helperText={helperText}
                inputProps={inputProps}
                InputProps={InputProps}
                InputLabelProps={InputLabelProps}
                onClick={() => {
                    setIsOpenStartDatePicker(true);
                }}
            />

            <Dialog
                open={isOpenStartDatePicker}
                onClose={() => {
                    setIsOpenStartDatePicker(false);
                }}
                {...(variantDatePicker === 'side-by-side' ? { maxWidth: 'md' } : {})}
            >
                <DialogTitle>
                    {variantDatePicker === 'two-steps' && (
                        <>Choose start date</>
                    )}

                    {variantDatePicker === 'side-by-side' && (
                        <>Choose start and end date</>
                    )}
                </DialogTitle>

                {variantDatePicker === 'two-steps' && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        {renderStartDatePicker(true)}
                    </LocalizationProvider>
                )}

                {variantDatePicker === 'side-by-side' && (
                    <div className='input_row'>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            {renderStartDatePicker(false)}
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            {renderEndDatePicker(false)}
                        </LocalizationProvider>
                    </div>
                )}

                {variantDatePicker === 'side-by-side' && (
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setIsOpenStartDatePicker(false);
                                handleCancel();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setIsOpenStartDatePicker(false);
                                handleConfirm();
                            }}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
            
            <Dialog
                open={isOpenEndDatePicker}
                onClose={() => {
                    setIsOpenEndDatePicker(false);
                }}
            >
                <DialogTitle> Choose end date </DialogTitle>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {renderEndDatePicker(true)}
                </LocalizationProvider>
            </Dialog>
        </>
    );

};