
import Dashboard from 'components/Dashboard/Dashboard';
import AdminList from 'components/Administrators/AdminList';
import {BrowserRouter as Router , Route, Routes, Navigate} from 'react-router-dom'
import Cookies from 'universal-cookie';
import CompanyList from 'components/Companies/CompanyList';
import PlanList from 'components/Plans/PlanList';
import Warehouses from 'components/Warehouses/WarehouseList';
import Products from 'components/Products/ProductsList';
import Categories from 'components/Categories/CategoriesList';
import Suppliers from 'components/Suppliers/SuppliersList';
import Brands from 'components/Brands/BrandsList';
import Users from 'components/Users/UsersList';
import Sync from 'components/Import/Sync';
import ReportsInventory from 'components/Reports/ReportsInventory';
import {Permissions} from './Permissions'; 
import Clients from 'components/Clients/ClientsList';
import MovementsConcepts from 'components/MovementsConcepts/ConceptsList';
import UndeliveredConcepts from 'components/UndeliveredConcepts/UndeliveredConceptList';
import AssetsConcepts from 'components/AssetsConcepts/AssetsConceptList'; 
import Guides from 'components/Guides/GuideList';
import Movements from 'components/Movements/MovementList';
import Notifications from 'components/Notifications/NotificationsList';
import Companies from 'components/Companies/CompanyList';
import Administrators from 'components/Administrators/AdminList';
import Plans from 'components/Plans/PlanList';
import Debt from 'components/Debt/ClientList';
import DebtReports from 'components/Reports/DebtReports' 
import PaymentMethods from 'components/PaymentMethods/PatmentMethodsList';
import Payments from 'components/Payments/PaymentsList';
import SystemCities from 'components/SystemCities/SystemCitiesList';
const cookies = new Cookies();
 
const componentMap: any = {
  Dashboard,
  Warehouses,
  Products,
  Categories,
  Suppliers,
  Brands,
  Users,
  Sync,
  ReportsInventory,
  AdminList,
  CompanyList,
  PlanList,
  Clients, 
  MovementsConcepts, 
  UndeliveredConcepts,
  AssetsConcepts,
  Guides,
  Movements,
  Notifications,
  Companies,
  Administrators,
  Plans,
  Debt,
  DebtReports,
  PaymentMethods,
  Payments,
  SystemCities
}




export default function AppRoutes(props:any) {

  const role_id = Number(localStorage.getItem('role_id'));
  const {setLoading, permissions} = props;
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/debt" element={<Debt setLoading={setLoading}/>} />
        {permissions.map((route:any, index:any) => {
          return route.components.map((item:any, index:any) => {
            const Component = componentMap[item.component];
            return <Route path={item.path} element={<Component setLoading={setLoading}/>} />
          })
        })}
      </Routes>
    </>

  );
}
