import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";

export default function GuideEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, client_guide_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    client_guide_id: client_guide_id ? client_guide_id : "null",
    guide_id: "",
    client_id: "",
    guide_code: "",
    guide_value: "",
    facture_code: "",
    license_plate: "",
    priority: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (client_guide_id) {
      axios
        .get(`${ProjectConfig.api_url}/guides/details`, {
          params: { client_guide_id },
        })
        .then((response) => {
          setFormValues(response.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      setFormValues(defaultValues);
    }
  }, [client_guide_id]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);

    axios
      .post(ProjectConfig.api_url + "/guides/addedit", body, {
        headers: {
          "ltkn": localStorage.getItem("ltkn"),
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.status === "success") {
          enqueueSnackbar("Guía guardada con éxito", { variant: "success" });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
      <div className="DialogContainer" style={{ maxWidth: "502px" }}>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Guía</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">
            <TextField
              id="guide_id"
              name="guide_id"
              label="ID de la Guía"
              type="text"
              required
              fullWidth
              value={formValues.guide_id}
              onChange={handleInputChange}
            />
            <TextField
              id="client_id"
              name="client_id"
              label="ID del Cliente"
              type="text"
              required
              fullWidth
              value={formValues.client_id}
              onChange={handleInputChange}
            />
            <TextField
              id="guide_code"
              name="guide_code"
              label="Código de la Guía"
              type="text"
              required
              fullWidth
              value={formValues.guide_code}
              onChange={handleInputChange}
            />
            <TextField
              id="guide_value"
              name="guide_value"
              label="Valor de la Guía"
              type="text"
              required
              fullWidth
              value={formValues.guide_value}
              onChange={handleInputChange}
            />
            <TextField
              id="facture_code"
              name="facture_code"
              label="Código de Factura"
              type="text"
              required
              fullWidth
              value={formValues.facture_code}
              onChange={handleInputChange}
            />
            <TextField
              id="license_plate"
              name="license_plate"
              label="Placa"
              type="text"
              required
              fullWidth
              value={formValues.license_plate}
              onChange={handleInputChange}
            />
            <TextField
              id="priority"
              name="priority"
              label="Prioridad"
              type="text"
              required
              fullWidth
              value={formValues.priority}
              onChange={handleInputChange}
            />
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
