import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { colors } from '@mui/material';

export default function UsersCount(props: any) {

  const { 
    chartData: {
      data = [],
      textIndicators = []
    }
   } = props;

  const endInnerradius = 140;
  const endOuterradius = 150;

  if (data.length === 0) return null;

  return (
    <div className="users_count">
      <PieChart
        series={
          data.map((d:any, i:any) => ({
            innerRadius: endInnerradius - (i * 15),
            outerRadius: endOuterradius - (i * 15),
            data: d,
            colors: colors,
            cornerRadius: 10,
          }))
        }
        width={400}
        height={350}
        slotProps={{
          legend: { hidden: true },
        }}
      />

      <div className="users_count_indicators">
        {textIndicators.map((d:any, i:any) => (
          <div className="users_count_indicator" key={i}>
            <div className="users_count_indicator_value">{d.value}</div>
            <div className="users_count_indicator_label">{d.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
