import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {ProjectConfig} from 'Global';
import { ApiRequest } from "GlobalFunctions";

export default function SupplierEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    supplier_id,
    loadList
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    supplier_id: supplier_id ? supplier_id : "null",
    supplier_code: "",
    supplier_name: "",
    supplier_doc: "",
    supplier_address: "",
    supplier_email: "",
    supplier_phone: "",
    contact_person: "",
  };
  
  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (supplier_id) {
      axios.get(`${ProjectConfig.api_url}/suppliers/details`, {
        params: { supplier_id },
      })
      .then((response) => {
        setFormValues(response.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    } else {
      setFormValues(defaultValues);
    }
  }, [supplier_id]);

  const handleInputChange = (e: { target: { name: any; value: any; } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);
    
    
    await ApiRequest({
      url: "/suppliers/addedit",
      method: "POST",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      setResponse: (response: any) => {
        if(response.status === "success"){
          enqueueSnackbar('Proveedor guardado con éxito', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      }
    });
  };

  return (
    <>
      <Dialog 
        open={openEditor}
        onClose={() => setOpenEditor(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <div className="DialogContainer" style={{ maxWidth: '502px' }}>
            <div className="ModuleWrapper">
                <div className="boxContainerHeader">
                    <div className="boxContainerTitle">
                        <h2 className="h2_title">Proveedor</h2>
                        <span className="h2_subtitle">Editar</span>
                    </div>
                </div>

                <div className="input_group">

                  <TextField
                    id="supplier_code"
                    name="supplier_code"
                    label="Código"
                    type="text"
                    required
                    fullWidth
                    value={formValues.supplier_code}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="supplier_name"
                    name="supplier_name"
                    label="Nombre del Proveedor"
                    type="text"
                    required
                    fullWidth
                    value={formValues.supplier_name}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="supplier_doc"
                    name="supplier_doc"
                    label="Documento"
                    type="text"
                    required
                    fullWidth
                    value={formValues.supplier_doc}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="supplier_address"
                    name="supplier_address"
                    label="Dirección"
                    type="text"
                    required
                    fullWidth
                    value={formValues.supplier_address}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="supplier_email"
                    name="supplier_email"
                    label="Correo Electrónico"
                    type="email"
                    required
                    fullWidth
                    value={formValues.supplier_email}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="supplier_phone"
                    name="supplier_phone"
                    label="Teléfono"
                    type="text"
                    required
                    fullWidth
                    value={formValues.supplier_phone}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="contact_person"
                    name="contact_person"
                    label="Persona de Contacto"
                    type="text"
                    required
                    fullWidth
                    value={formValues.contact_person}
                    onChange={handleInputChange}
                  />
                </div>
            </div>
        </div>

        <div className="dialogButtons">
          <Button
              onClick={() => setOpenEditor(false)}
              variant="outlined"
              className="button_2"
          >
            Cancelar
          </Button>

          <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
        </div>
      </Dialog>
    </>
  );
}
