import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';
import { LinearProgress } from '@mui/material';

const cookies = new Cookies();

interface IAutocompleteList {
  name: string;
  id: number;
}



export default function AutocompleteCategories(props: any) {

  const {value, onChange, error, disabled} = props;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpenList = () => {
  setOpen(true);
  };





  /*=================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IProjectList {
      inputValue?: string,
      category_id?: number,
      category_name: string,
    };
    const filter = createFilterOptions<IProjectList>();

    //setClientList
    const [categorylist, setCategoryList] = useState<any []>([]);
    const [category, setCategory] = useState<IProjectList | null>(null);
    
    useEffect(() => {
      
      axios.get(ProjectConfig.api_url + "/categories/list", 
      {
        headers: {
          "ltkn": localStorage.getItem("ltkn")
        },
        params: {
          full_list: true,
        }
      })
        .then((response) => {
          console.log("CategoryList",response.data);
          setCategoryList(response.data.list);
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
    }, []);
    /* ================================================= 
    ===================================================*/


    let item_name = "";
    if (categorylist && (value && value!=0)) 
    {
      item_name = categorylist.find(obj => {
        return obj.category_id === parseInt(value); 
      })
    } 
    
    
  return (
    <>
      {categorylist.length > 0 ? (
        <Autocomplete
          value={item_name}
          disabled={disabled}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setCategory({
                category_name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setCategory({
                category_name: newValue.inputValue,
              });
            } else {
              setCategory(newValue);
              if (newValue?.category_id!=null)
              {
                onChange({
                    target: {
                        value: newValue.category_id.toString(),
                        name: "category_id",
                    }
                })
              }
              else {
                onChange({
                  target: {
                      value: "",
                      name: "category_id",
                  }
                })
              }
              
            }
            
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          autoComplete={false}
          fullWidth
          id="free-solo-with-text-demo"
          options={categorylist}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.category_name;
          }}
          renderOption={(props, option) => <li className="flex_select" {...props}>
            <div className="flex_option_1">{option.category_name}</div>
          </li>}
          freeSolo
          renderInput={(params) => (
            <TextField 
            {...params} 
            label="Categoría" 
            error={error}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            
            placeholder='Categoría'
            />
          )}
          /> 
        )
        :
        (
          <>
          
          <LinearProgress />
          
          </>
        )
      }
        
      
    </>
  );
}
