import { useEffect, useRef } from 'react';

const useIdleLogout = (onLogout: () => void, idleTimeout: number = 600000) => {
  const idleTimer = useRef<number>();

  const resetTimer = () => {
    clearTimeout(idleTimer.current);
    idleTimer.current = window.setTimeout(onLogout, idleTimeout);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];

    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    resetTimer(); // Initialize the timer

    return () => {
      clearTimeout(idleTimer.current);
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [idleTimeout, onLogout]);

  return null;
};

export default useIdleLogout;