import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'elements/Box';
import { Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';

import 'assets/css/main.css'; 

import BrandsEditor from './BrandsEditor';
import { ApiRequest } from 'GlobalFunctions';

export default function BrandList(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);
  
  const [list, setList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const currentPage = parseInt(localStorage.getItem('brandPage') || "1");
  const [page, setPage] = useState(currentPage);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page]);

  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: "/brands/list",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        page: page,
      },
      setResponse: (response: any) => {
        setList(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      },
    });

    localStorage.setItem('brandPage', page.toString());
  };

  const handleDelete = (brand_id: number) => {
    setActiveItem(brand_id);
    setOpenAlert(true);
  };

  const deleteItem = () => {
    const body = {
      brand_id: activeItem
    };

    axios.post(ProjectConfig.api_url + "/brands/deleteItem", body) // Adjust endpoint if needed
      .then((response) => {
        if (response.data.status === "success") {
          enqueueSnackbar('Marca eliminada con éxito', { variant: 'success' });
          loadList();
        }
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setOpenAlert(false);
      });
  };

  return (
    <div id='BrandList' className='ModuleContainer'>
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Marcas</h2>
            <span className="h2_subtitle">Lista de marcas</span>
          </div>
          <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveItem(null);
                setOpenEditor(true);
              }}
              startIcon={<AddIcon />}
            >
              Crear marca
            </Button>
          </div>
        </div>

        <Box id='BrandListIn' className='box100'>
          <TableContainer>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>NOMBRE</TableCell>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, i) => (
                  <TableRow key={i} hover role="checkbox">
                    <TableCell>{item.brand_name}</TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        startIcon={<ModeEditIcon />}
                        onClick={() => {
                          setActiveItem(item.brand_id);
                          setOpenEditor(true);
                        }}
                      >
                        Editar
                      </Button>

                      {(localStorage.getItem('role_id') === "1" || localStorage.getItem('role_id') === "2") &&
                      <IconButton aria-label="delete" color="primary" onClick={() => handleDelete(item.brand_id)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
            style={{ marginTop: "20px", alignSelf: "center" }}
          />
        </Box>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="¿Está seguro que desea eliminar esta marca?"
        send={deleteItem}
      />

      <BrandsEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        brand_id={activeItem}
        loadList={loadList}
      />
    </div>
  );
};
