import React from 'react';
import { Typography, Button, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { validateForm, ApiRequest } from 'GlobalFunctions';
import moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';

export default function PaymentResult(props: any) {
  const { open, setOpen, ltkn, callback, payment_id } = props;

const exportPayment = async () => {
  await ApiRequest({
    url: '/payments/exportPayment',
    method: 'GET',
    headers: {
      ltkn: localStorage.getItem('ltkn'),
    },
    query: {
      payment_id,
    },
    setResponse: (response: any) => {
      const url = response;
      const title = `Recaudo_${moment().format('YYYY-MM-DD')}.pdf`;

      // Abrir el archivo en una nueva pestaña
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        console.error('No se pudo abrir la nueva pestaña');
      }
    },
    setError: (error: any) => {
      console.error('Error al exportar el reporte:', error);
    },
  });
};


  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ marginTop: 3 }}>

        <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={exportPayment} startIcon={<GetAppIcon />}>
            Exportar
          </Button>
          
        </Box>
      </Box>
    </Box>
  );
}
