import axios from "axios";
import { useEffect, useState } from "react";
import Box from "elements/Box";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ProjectConfig } from "Global";
import AlertDialog from "elements/AlertDialog";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useSnackbar } from "notistack";

import "assets/css/main.css";
import { useNavigate, useParams } from "react-router-dom";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AutocompleteStandard from "elements/AutocompleteStandard";
import AutocompleteReportUsers from "elements/AutocompleteReportUsers";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import ReportViewer from "./ReportViewer";
import AutocompleteField from "elements/AutocompleteField";
import AutocompleteWarehouses from "elements/AutocompleteWarehouses";
import { ApiRequest } from "GlobalFunctions";

export default function ReportsForm(props: any) {
  const { setLoading } = props;

  const [loadingPopup, setLoadingPopup] = useState(false);

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

  useEffect(() => {
    setLoading(false);
  }, []);

  const defaultValues: any = {
    date: null,
    user_id: null,
    warehouse_id: null,
  };

  

  

  const [exportType, setExportType] = useState("");

  const [formValues, setFormValues] = useState({...defaultValues});
  const [reportName, setReportName] = useState("");
  

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
    SUBMIT
  =======================================*/
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [data, setData] = useState([]);
  const [openViewer, setOpenViewer] = useState(false);

  const getReport = async (download = "") => {
    console.log(formValues);
    setLoading(true);
    setLoadingPopup(true);

    await ApiRequest({
      url: "/reports/getReport",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        ...formValues,
        exportType,
        module:"inventory",
        download
      },
      setResponse: (response: any) => {
        if(response.status === "success"){
          setData(response.data);
          setOpenViewer(true);

          if(download === 'pdf') {
            console.log(response.pdfUrl);
            window.open(response.pdfUrl, '_blank');
          }
          if(download === 'excel'){
            window.open(response.fileUrl, '_blank');
          }
        }
        else{
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
        setLoadingPopup(false);
      },
    });
  };
  /*====================================
  =====================================*/

  console.log(reportName);

  return (
    <>
      <div id="SupplierList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Reportes Inventario</h2>
              <span className="h2_subtitle">Selecciona el tipo de exportación</span>
            </div>
            <div className="boxContainerActions"></div>
          </div>

          <Box id="SupplierListIn" className="box100">

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Reporte</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={exportType}
                label="Reporte"
                name="export_type"
                onChange={(e: any) => {
                  setExportType(e.target.value);
                  setFormValues({ ...defaultValues });
                  setReportName(e.target.value);
                }}
              >
                <MenuItem value={"GetCountUnitInventory"} aria-label="Conteo de inventario unidades">Conteo de inventario unidades</MenuItem>
                <MenuItem value={"GetCountUnitInventoryWarehouse"}>Conteo de inventario x almacenes</MenuItem>
                <MenuItem value={"GetCountUsersUnit"}>Conteo de inventario unidades x usuario</MenuItem>
                <MenuItem value={"GetCountUsersMeasure"}>Conteo de inventario x medida</MenuItem>
                <MenuItem value={"GetCountUsersDifferences"}>Diferencias</MenuItem>
                <MenuItem value={"GetPhysicalCountVersusInventory"}>Inventario físico vs sistema</MenuItem>
                <MenuItem value={"GetMissingAndLeftoverCount"}>Inventario faltante vs sobrante</MenuItem>
                <MenuItem value={"GetPrice"}>Inventario faltante vs sobrante x valores</MenuItem>
                <MenuItem value={"GetStock"}>Inventario físico vs stock</MenuItem>
                <MenuItem value={"GetSimilarCount"}>Similares</MenuItem>
              </Select>
            </FormControl>

            {exportType && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                  
                  label="Fecha"
                  inputFormat="MMMM DD, yyyy"
                  value={formValues.date}
                  InputProps={{
                      endAdornment: (
                      <EventIcon />
                      ),
                  }}
                  showToolbar={false}
                  closeOnSelect 
                  onChange={(newValue:any) => {
                      const val = newValue? (newValue).format('YYYY-MM-DD') : '';
                      setFormValues({
                          ...formValues,
                          date: val,
                      });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  />
              </LocalizationProvider>
            )}

            {formValues.date && (
              exportType === "GetCountUsersUnit" || 
              exportType === "GetCountUsersMeasure" ||
              exportType === "GetPhysicalCountVersusInventory" || 
              exportType === "GetMissingAndLeftoverCount" || 
              exportType === "GetPrice" || 
              exportType === "GetStock" || 
              exportType === "GetSimilarCount") 
            && (
              <AutocompleteReportUsers
                value={formValues.user_id}
                date={formValues.date}
                onChange={(e: any) => {
                  setFormValues({
                    ...formValues,
                    user_id: e.target.value,
                  });
                }}
              />
            )}

            {formValues.date && (exportType === "GetCountUnitInventoryWarehouse") && (
              <AutocompleteWarehouses
                value={formValues.warehouse_id}
                onChange={(e: any) => {
                  setFormValues({
                    ...formValues,
                    warehouse_id: e.target.value,
                  });
                }}
              />
            )}




            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                getReport();
              }}
              startIcon={<CloudDownloadOutlinedIcon />}
            >
              Ver
            </Button>

          </Box>
        </div>
      </div>

      <ReportViewer
        open = {openViewer}
        setOpen = {setOpenViewer}
        data = {data}
        loading = {loadingPopup}
        setLoading = {setLoading}
        reportName = {reportName}
        getReport = {getReport}
      />
    </>
    
  );
}
