import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";
import AutocompleteSuppliers from "elements/AutocompleteSuppliers";
import AutocompleteWarehouses from "elements/AutocompleteWarehouses";
import AutocompleteParentProducts from "elements/AutocompleteParentProducts";
import AutocompleteBrands from "elements/AutocompleteBrands";
import AutocompleteCategories from "elements/AutocompleteCategories";
import { ApiRequest } from "GlobalFunctions";
import ImageUploader from "elements/ImageUploader";

export default function ProductEditor(props : any) {
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    product_id,
    loadList
  } = props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const defaultValues: any = {
    product_id: product_id ? product_id : "null",
    parent_product_id: "",
    product_name: "",
    product_code: "",
    base_price: "",
    price_1: "",
    price_2: "",
    price_3: "",
    product_existences: "",
    product_package: "",
    product_conversion: "",
    product_measure_type: "",
    supplier_id: "",
    warehouse_id: "",
    product_warehouse_order: "",
    unit_measure: "",
    brand_id: "",
    category_id: "",
    barcode: "",
    tax_1: 0,
    tax_2: 0,
    is_active: 1,
    inventory: [],
    inventory_total: 0,
    image: '',
  };
  
  const [formValues, setFormValues] = useState(defaultValues)

  const [hasParent, setHasParent] = useState(false);
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    if (formValues.parent_product_id !== null && formValues.parent_product_id !== "") {
      setHasParent(false);
    } else {
      setHasParent(true);
    }
  }, [formValues.parent_product_id]);

  useEffect(() => {
    if (product_id) {
      axios.get(ProjectConfig.api_url + "/products/details", {
          params: {
            product_id: product_id,
          },
        })
        .then((response) => {
          console.log("Product details", response.data)
          setFormValues(response.data.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setFormValues(defaultValues);
    }
    
  } , [product_id])

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    const calculateUnitMeasure = () => {
      const existences = parseInt(formValues.product_existences, 10);
      const packageSize = parseInt(formValues.product_package, 10);
      if (!isNaN(existences) && !isNaN(packageSize) && packageSize > 0) {
        const packages = Math.floor(existences / packageSize);
        const units = existences % packageSize;
        if (units === 0) {
          return `${packages} empaques`;
        } else {
          return `${packages} empaques y ${units} unidades`;
        }
      }
      return formValues.unit_measure; // Use current value instead of returning an empty string to avoid undefined
    };
  
    const unitMeasure = calculateUnitMeasure();
    if (formValues.unit_measure !== unitMeasure) {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        unit_measure: unitMeasure,
      }));
    }
  }, [formValues.product_existences, formValues.product_package, formValues.unit_measure]);
  
  /*=======================================
  HANDLE SUBMIT
  =======================================*/

  const handleSubmit = async () => {  
    console.log("formValues", formValues);
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));

    if (files) {
      body.append("image", files);
    }
    
    await ApiRequest({
      url: "/products/addedit",
      method: "post",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar("Guardado con éxito", { variant: "success" });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
      },
    });
  };

  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="DialogContainer" style={{minWidth:'400px'}}>
              <div className="ModuleWrapper">
                  <div className="boxContainerHeader">
                      <div className="boxContainerTitle">
                          <h2 className="h2_title">Producto</h2>
                          <span className="h2_subtitle">Editar</span>
                      </div>
                  </div>

                  <div className="input_group">

                    <AutocompleteParentProducts
                      value={formValues.parent_product_id}
                      onChange={(e: any) => {
                        setFormValues({
                          ...formValues,
                          parent_product_id: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      id="product_name"
                      name="product_name"
                      label="Nombre del Producto"
                      type="text"
                      required
                      fullWidth
                      value={formValues.product_name}
                      onChange={handleInputChange}
                    />
                      <TextField
                        id="product_code"
                        name="product_code"
                        label="Código del Producto"
                        type="text"
                        required
                        fullWidth
                        value={formValues.product_code}
                        onChange={handleInputChange}
                      />

                      <div className="input_row">
                        <AutocompleteBrands
                          value={formValues.brand_id}
                          onChange={(e: any) => {
                            setFormValues({
                              ...formValues,
                              brand_id: e.target.value,
                            });
                          }}
                          disabled= {!hasParent}
                        />

                        <AutocompleteCategories 
                          value={formValues.category_id}
                          onChange={(e: any) => {
                            setFormValues({
                              ...formValues,
                              category_id: e.target.value,
                            });
                          }}
                          disabled= {!hasParent}
                        />
                      </div>

                    <h3>Precios</h3>
                    <div className="input_row">

                      <TextField
                        id="base_price"
                        name="base_price"
                        label="Precio Base"
                        type="text"
                        required
                        fullWidth
                        value={formValues.base_price}
                        onChange={handleInputChange}
                        disabled={!hasParent}
                      />

                      <TextField
                        id="price_1"
                        name="price_1"
                        label="Precio 1"
                        type="text"
                        required
                        fullWidth
                        value={formValues.price_1}
                        onChange={handleInputChange}
                        disabled={!hasParent}
                      />

                      <TextField
                        id="price_2"
                        name="price_2"
                        label="Precio 2"
                        type="text"
                        required
                        fullWidth
                        value={formValues.price_2}
                        onChange={handleInputChange}
                        disabled={!hasParent}
                      />

                      <TextField
                        id="price_3"
                        name="price_3"
                        label="Precio 3"
                        type="text"
                        required
                        fullWidth
                        value={formValues.price_3}
                        onChange={handleInputChange}
                        disabled={!hasParent}
                      />
                    </div>

                    <h3>Impuestos</h3>
                    <div className="input_row">
                      <TextField
                        id="tax_1"
                        name="tax_1"
                        label="IVA (%)"
                        type="text"
                        required
                        fullWidth
                        value={formValues.tax_1}
                        onChange={handleInputChange}
                        disabled={!hasParent}
                      />

                      <TextField
                        id="tax_2"
                        name="tax_2"
                        label="Ipoconsumo"
                        type="text"
                        required
                        fullWidth
                        value={formValues.tax_2}
                        onChange={handleInputChange}
                        disabled={!hasParent}
                      />
                    </div>

                    <TextField
                      id="product_package"
                      name="product_package"
                      label="Empaque"
                      type="text"
                      required
                      fullWidth
                      value={formValues.product_package}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="product_conversion"
                      name="product_conversion"
                      label="Conversión"
                      type="text"
                      required
                      fullWidth
                      value={formValues.product_conversion}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="product_measure_type"
                      name="product_measure_type"
                      label="Unidad de Medida"
                      type="text"
                      required
                      fullWidth
                      value={formValues.product_measure_type}
                      onChange={handleInputChange}
                    />
                    
                    <AutocompleteSuppliers
                      value={formValues.supplier_id}
                      onChange={(e: any) => {
                        setFormValues({
                          ...formValues,
                          supplier_id: e.target.value,
                        });
                      }}
                    />

                    <AutocompleteWarehouses
                      value={formValues.warehouse_id}
                      onChange={(e: any) => {
                        setFormValues({
                          ...formValues,
                          warehouse_id: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      id="product_warehouse_order"
                      name="product_warehouse_order"
                      label="Orden de bodega"
                      type="text"
                      required
                      fullWidth
                      value={formValues.product_warehouse_order}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="barcode"
                      name="barcode"
                      label="Código de Barras"
                      type="text"
                      required
                      fullWidth
                      value={formValues.barcode}
                      onChange={handleInputChange}
                    />

                    <FormControlLabel
                      control={
                        <ISwitch
                          checked={formValues.is_active}
                          onChange={(e: any) => {
                            setFormValues({
                              ...formValues,
                              is_active: e.target.checked,
                            });
                          }}
                          name="is_active"
                        />
                      }
                      label="Activo"
                    />

                    <h3>Inventario</h3>
                    {formValues.inventory.map((inv: any, index: number) => (
                      <TextField
                        key={index}
                        label={inv.warehouse_name}
                        id={`stock_${inv.inventory_id}`}
                        name={`stock_${inv.inventory_id}`}
                        type="text"
                        value={inv.stock}
                        onChange={(e: any) => {
                          const newInventory = formValues.inventory.map((item: any) => {
                            if (item.inventory_id === inv.inventory_id) {
                              return {
                                ...item,
                                stock: e.target.value,
                              };
                            }
                            return item;
                          });
                          setFormValues({
                            ...formValues,
                            inventory: newInventory,
                            inventory_total: newInventory.reduce((acc: number, item: any) => acc + parseInt(item.stock, 10), 0),
                          });
                        }}
                      />
                    ))}
                    <div className="inventory_row">
                      <h3>Total existencias</h3> 
                      <label>{formValues.inventory_total}</label>
                    </div>
                    <ImageUploader 
                      className="product_image"
                      image={formValues.image} 
                      setImage={(newImage) => setFormValues({ ...formValues, image: newImage })}
                      setFiles={setFiles} 
                    />
                  </div>
                </div>
              </div>
         
          <div className="dialogButtons">
            <Button
                onClick={() => setOpenEditor(false)}
                variant="outlined"
                className="button_2"
            >
              Cancelar
            </Button>

            <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
          </div>

        </Dialog>
      </>
  );
}