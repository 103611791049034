import axios from "axios";
import { useEffect, useState } from "react";
import Box from "elements/Box";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useSnackbar } from "notistack";
import DateRange from "elements/DateRange";
import moment from "moment";
import ReportViewer from "./ReportViewer";
import { ApiRequest } from "GlobalFunctions";
import EventIcon from '@mui/icons-material/Event';
import AutocompleteReportUsers from "elements/AutocompleteReportUsers";
import AutocompleteField from "elements/AutocompleteField";

export default function DebtReports(props: any) {
  const { setLoading } = props;

  const [loadingPopup, setLoadingPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(false);
  }, []);

  const defaultValues: any = {
    date: null,
    user_id: null,
    client_id: null,
    payment_method_id: null,
  };

  const [exportType, setExportType] = useState("");
  const [formValues, setFormValues] = useState({ ...defaultValues });
  const [reportName, setReportName] = useState("");

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [data, setData] = useState([]);
  const [openViewer, setOpenViewer] = useState(false);

  const getReport = async (download = "") => {
    setLoading(true);
    setLoadingPopup(true);
    
    //if report is byClient, verify if client is selected
    if(exportType === "byClient" && !formValues.client_id){
      enqueueSnackbar("Debes seleccionar un cliente", { variant: 'error' });
      setLoading(false);
      setLoadingPopup(false);
      return;
    }

    await ApiRequest({
      url: "/reports/getReport",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        ...formValues,
        exportType,
        module: "debt",
        download
      },
      setResponse: (response: any) => {
        if(response.status === "success"){
          setData(response.data);
          setOpenViewer(true);

          if(download === 'pdf') {
            console.log(response.pdfUrl);
            window.open(response.pdfUrl, '_blank');
          }
          if(download === 'excel'){
            window.open(response.fileUrl, '_blank');
          }
        }
        else{
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
        setLoadingPopup(false);
      },
    });
  };

  return (
    <>
      <div id="SupplierList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Reportes Cartera</h2>
              <span className="h2_subtitle">Selecciona el tipo de exportación</span>
            </div>
          </div>

          <Box id="SupplierListIn" className="box100">

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Reporte</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={exportType}
                label="Reporte"
                name="export_type"
                onChange={(e: any) => {
                  setExportType(e.target.value);
                  setFormValues({ ...defaultValues });
                  setReportName(e.target.value);
                }}
              >
                <MenuItem value={"byAge"}>Cartera por edades</MenuItem>
                <MenuItem value={"OverdueInvoices"}>Cartera vencida</MenuItem>
                <MenuItem value={"byClient"}>Cartera por cliente</MenuItem>
                <MenuItem value={"byPaymentMethod"}>Recaudo por método de pago</MenuItem>
                <MenuItem value={"byUser"}>Recaudo por usuario</MenuItem>
              </Select>
            </FormControl>

            {exportType && (
              (exportType !== "xxxTEMP"
              ) ? (
                <DateRange
                  className="filters_datePicker"
                  label="Date range"
                  variant="outlined"
                  fullWidth
                  value={{
                    startDate: formValues.date?.startDate || null,
                    endDate: formValues.date?.endDate || null,
                  }}
                  format="MMMM DD, yyyy"
                  onChange={(e: any) => {
                    if (e.startDate && e.endDate) {
                      const startDate = moment(e.startDate).format("YYYY-MM-DD");
                      const endDate = moment(e.endDate).format("YYYY-MM-DD");

                      setFormValues({
                        ...formValues,
                        date: { startDate, endDate },
                      });
                    }
                  }}
                  variantDatePicker="side-by-side"
                />
              ) : (

                 <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                  
                  label="Fecha"
                  inputFormat="MMMM DD, yyyy"
                  value={formValues.date}
                  InputProps={{
                      endAdornment: (
                      <EventIcon />
                      ),
                  }}
                  showToolbar={false}
                  closeOnSelect 
                  onChange={(newValue:any) => {
                      const val = newValue? (newValue).format('YYYY-MM-DD') : '';
                      setFormValues({
                          ...formValues,
                          date: val,
                      });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  />
              </LocalizationProvider>

              )
            )}

            {formValues.date && (
              exportType === "byAge" ||
              exportType === "OverdueInvoices" ||
              exportType === "byUser"
            ) 
            && (
              <AutocompleteField
                label="Usuario"
                endpoint="/reports/debtUserList"
                endpoint_params={{ 
                  filters: {role_id: 8}, 
                  exportType,
                  startDate: formValues.date?.startDate,
                  endDate: formValues.date?.endDate
                }}
                value={formValues.user_id}
                onChange={(data: any) => {
                  console.log("data", data?.value);
                    setFormValues({...formValues, user_id: data?.value || null});
                }}
              />
            )}


            {formValues.date && exportType === "byPaymentMethod" && (
              <AutocompleteField
                label="Método de pago"
                endpoint="/payments/paymentMethodsList"
                value={formValues.payment_method_id}
                onChange={(data: any) => {
                  setFormValues({ ...formValues, payment_method_id: data?.value || null });
                }}
              />
            )}

            {formValues.date && exportType === "byClient" && (
              <AutocompleteField
                label="Cliente"
                endpoint="/clients/list"
                value={formValues.client_id}
                onChange={(data: any) => {
                  setFormValues({ ...formValues, client_id: data?.value || null });
                }}
              />
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                getReport();
              }}
              startIcon={<CloudDownloadOutlinedIcon />}
            >
              Ver
            </Button>

          </Box>
        </div>
      </div>

      <ReportViewer
        open={openViewer}
        setOpen={setOpenViewer}
        data={data}
        loading={loadingPopup}
        setLoading={setLoading}
        reportName={reportName}
        getReport={getReport}
      />
    </>
  );
}
