import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';
import { List } from 'devextreme-react';
import { Button, Dialog, LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import EmptyElement from './EmptyElement';
import ParentEditor from 'components/Products/ParentEditor';
import { ApiRequest } from 'GlobalFunctions';

const cookies = new Cookies();

interface IAutocompleteList {
  name: string;
  id: number;
}



export default function AutocompleteParentProducts(props: any) {

  const company_id = cookies.get('company_id');

  const {value, onChange, error} = props;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpenList = () => {
  setOpen(true);
  };


  const [openParentEditor, setOpenParentEditor] = useState(false);

  const [productName, setProductName] = useState("");

  /*=================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IProjectList {
      inputValue?: string,
      product_id?: number,
      product_name: string,
    };
    const filter = createFilterOptions<IProjectList>();

    //setClientList
    const [list, setList] = useState<any []>([]);
    const [parent_product, setParentProduct] = useState<IProjectList | null>(null);
    
    useEffect(() => {
      loadList();
    }, []);

    const loadList = async () => {
     
      await ApiRequest({
        url: "/products/list",
        method: "get",
        headers: {
          ltkn: localStorage.getItem("ltkn"),
        },
        query: {
          full_list: true,
        },
        setResponse: (response: any) => {
          setList(response.list);
          setLoading(false);
        },
      });
    }


    /* ================================================= 
    ===================================================*/


    let item_name = "";
    if (list && (value && value!=0)) 
    {
      console.log("value", value);
      
      item_name = list.find(obj => {
        return obj.product_id === parseInt(value); 
      })
    } 
    
    
  return (
    <>

     {list.length > 0 ? 
     (
      <Autocomplete
        value={item_name}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setParentProduct({
              product_name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            console.log("newValue", newValue);
            setParentProduct({
              product_name: newValue.inputValue,
              product_id: 0,
            });

            setProductName(newValue.inputValue);
            setOpenParentEditor(true);
            

          } else {
            setParentProduct(newValue);
            if (newValue?.product_id!=null)
            {
              onChange({
                  target: {
                      value: newValue.product_id.toString(),
                      name: "product_id",
                  }
              })
            }
            else {
              onChange({
                target: {
                    value: "",
                    name: "product_id",
                }
              })
            }
            
          }
          
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.product_name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              product_name: `Agregar "${inputValue}"`,
              
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        fullWidth
        id="free-solo-with-text-demo"
        options={list}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.product_name;
        }}
        renderOption={(props, option) => <li className="flex_select" {...props} key={option.product_id}>
          <div className="flex_option_1">{option.product_name}</div>
        </li>}
        freeSolo
        renderInput={(params) => (
          <TextField 
          {...params} 
          label="Producto padre" 
          error={error}
          
          placeholder='Producto padre'
          />
        )}
        
      /> 
      )
      :
      (
        <>
        
        <LinearProgress />
        
        </>
      )}


      <ParentEditor
        openEditor={openParentEditor}
        loadList={loadList}
        setOpenEditor={setOpenParentEditor}
        setParentProduct={setParentProduct}
        product_name={productName}
        setLoading={setLoading}
      />
    </>
  );
}
