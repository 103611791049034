import React from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
    TextField,
    Link,
    Button
} from '@mui/material';

import Box from 'elements/Box';
import { ProjectConfig } from 'Global';

const cookies = new Cookies();

export default function FormLogin( props: any ) {

    const {
        logo,
        setLoading,
        setState,
        email,
        setEmail,
        loginData,
        setLoginData,
        loadingButton,
        setLoadingButton,
        setOpenRegister,
        sign_in,
    } = props;
    
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    /*================================================
    SIGN IN (SUBMIT)
    ================================================*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoadingButton(true);
        
        sign_in();

    
    };

    /*================================================
    ================================================*/


    return(
        <Box id='loginBox'>
            <img className='loginLogo' src={logo}></img>

            <p>Ingresa a tu cuenta para continuar</p>
            
            <form onSubmit={handleSubmit} className='form'>
            <TextField
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                value={loginData.email}
                onChange={(e) => {
                    setLoginData({ ...loginData, email: e.target.value });
                    setEmail(e.target.value);
                }}
                type="text"
                autoComplete="email"
                autoFocus
            />
            <TextField
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                value={loginData.password}
                onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
            />
            <LoadingButton
                size="small"
                type="submit"
                loading={loadingButton}
                fullWidth
                variant="contained"
                sx={{ fontSize: '13px', padding: '10px 0px'}}
            >
                Iniciar sesión
            </LoadingButton>

            
{/* 
            <Link
                component="button"
                variant="body2"
                onClick={() => {
                    setState('forgotPass')
                }}
            >
              Olvide mi clave
            </Link> */}
            </form>
        </Box>
    );
}