import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';
import { List } from 'devextreme-react';
import { Button, Dialog, LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import EmptyElement from './EmptyElement';
import ParentEditor from 'components/Products/ParentEditor';
import { ApiRequest } from 'GlobalFunctions';

const cookies = new Cookies();

interface IAutocompleteList {
  name: string;
  id: number;
}



export default function AutocompleteReportUsers(props: any) {

  const {value, onChange, error, date} = props;
  const [loading, setLoading] = useState(false);


  /*=================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IProjectList {
      inputValue?: string,
      user_id?: number,
      name: string,
    };
    const filter = createFilterOptions<IProjectList>();

    //setClientList
    const [list, setList] = useState<any []>([]);
    const [parent_product, setParentProduct] = useState<IProjectList | null>(null);
    
    useEffect(() => {
      setLoading(true)

      getUsers();

    }, [date]);

    const getUsers = async () => {

      await ApiRequest({
        url: "/reports/GetCountersUsers",
        method: "get",
        headers: {
          ltkn: localStorage.getItem("ltkn"),
        },
        query: {
          date: date
        },
        setResponse: (response: any) => {
          setList(response.list);
          setLoading(false);
        },
      });
    }



    /* ================================================= 
    ===================================================*/


    let item_name = "";
    if (list && (value && value!=0)) 
    {
      item_name = list.find(obj => {
        return obj.user_id === parseInt(value); 
      })
    } 
    
  if(loading){
    return (
      <>Cargando usuarios</>
    )
  }

  if (list.length == 0){
    return (
      <EmptyElement title="Sin Usuarios" />
    )
  }
    
  return (
    <>

     {list.length > 0 ? 
     (
      <Autocomplete
        value={item_name}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            
            
          } else if (newValue && newValue.inputValue) {
            console.log("newValue", newValue);

          } else {
            setParentProduct(newValue);
            if (newValue?.user_id!=null)
            {
              onChange({
                  target: {
                      value: newValue.user_id.toString(),
                      name: "user_id",
                  }
              })
            }
            else {
              onChange({
                target: {
                    value: "",
                    name: "user_id",
                }
              })
            }
            
          }
          
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Agregar "${inputValue}"`,
              
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        fullWidth
        id="free-solo-with-text-demo"
        options={list}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        renderOption={(props, option) => <li className="flex_select" {...props}>
          <div className="flex_option_1">{option.name}</div>
        </li>}
        freeSolo
        renderInput={(params) => (
          <TextField 
          {...params} 
          label="Usuario" 
          error={error}
          
          placeholder='Usuario'
          />
        )}
        
      /> 
      )
      :
      (
        <>
        
        <LinearProgress />
        
        </>
      )}


     
    </>
  );
}
