import { PersonPin } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const cookies = new Cookies();

export default function MenuButton(props: any) {

  const { label, to, startIcon = null, onClickCallback, company_id = null, disabled = false, type = null, drawerOpen } = props;

  const checkActiveMenu = (href: string) => {
    return window.location.pathname === href || window.location.pathname.startsWith(`${href}/`);
  };

  const styleMenu = {
    buttons: {
      textDecoration: 'none',
      padding: '10px 20px 10px 10px',
      width: '100%',
      color: '#1D3B47',
      textTransform: 'none',
      gap: '10px',
      justifyContent: 'flex-start',
      //svg
      '& svg': {
        width: '25px',
        height: '25px',
        marginRight: drawerOpen? '10px' : '0px',
        color: checkActiveMenu(to)? '#fff' : '#1D3B47',
      },
      //& span
      '& span': {
        marginRight: drawerOpen? '10px' : '0px',
      }
    }
  }
  


  return (
    
    <Button
        disabled={disabled}
        component={Link}
        startIcon={startIcon}
          to={to}
        sx={styleMenu.buttons}
        onClick={onClickCallback}
        style={{
          color: checkActiveMenu(to)? '#fff' : '#1D3B47',
          backgroundColor: checkActiveMenu(to)? '#1e3c47' : 'transparent',
        }}
      >
        {label}
      </Button>
  );
}