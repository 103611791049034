import React from 'react';
import { CircularProgress } from '@mui/material';

import 'assets/css/main.css';

export default function LoadingCircle( props: any ) {

    const { 
      loading,
      className
    } = props;
    
    return (
      <div
      id="customLoader"
      className={`${
        !loading ? "loaderHide" : "loaderView"
      } ${className ? className : ""}`}
    >
    
          <CircularProgress />
        </div>
    )
}