import axios from "axios";
import { useEffect, useState } from "react";
import Box from "elements/Box";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ProjectConfig } from "Global";
import AlertDialog from "elements/AlertDialog";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useSnackbar } from "notistack";

import "assets/css/main.css";
import { useNavigate, useParams } from "react-router-dom";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import ImportPreviewer from "./ImportPreviewer";

export default function SyncForm(props: any) {
  const { setLoading } = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  useEffect(() => {
    setLoading(false);
  }, []);

  const defaultValues: any = {
    upload_type: "products",
    file: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [files, setFiles] = useState<any>([]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
    SUBMIT
  =======================================*/
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [openViewer, setOpenViewer] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    console.log(formValues);
    //FILES NEEDS TO BE UPLOADED USING FORMDATA, APART FROM THE OTHER FIELDS
    const formData = new FormData();
    formData.append("files", files);
    formData.append("formValues", JSON.stringify(formValues));

    //AXIOS
    axios
      .post(ProjectConfig.api_url + "/sync/checkImportFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "ltkn": localStorage.getItem("ltkn")
        },
        onUploadProgress: (progressEvent: any) => {
          setProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          enqueueSnackbar(`${response.data.importedQuantity} registros encontrados`, { variant: "success" });
          setData(response.data.data);
          setOpenViewer(true);
        } else {
          enqueueSnackbar("Error al sincronizar", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  /*====================================
  =====================================*/

  return (
    <div id="SupplierList" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Sincronizar</h2>
            <span className="h2_subtitle">Sube el archivo de acuerdo al tipo de importación</span>
          </div>
          <div className="boxContainerActions"></div>
        </div>

        <Box id="SupplierListIn" className="box100">

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Tipo de importación</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formValues.upload_type}
            label="Tipo de importación"
            name="upload_type"
            onChange={handleInputChange}
          >
            <MenuItem value={"products"}>Productos</MenuItem>
            <MenuItem value={"suppliers"}>Proveedores</MenuItem>
            <MenuItem value={"clients"}>Clientes</MenuItem>
            <MenuItem value={"guides"}>Rutas</MenuItem>
            <MenuItem value={"invoices"}>Cartera</MenuItem>
          </Select>


          
        </FormControl>


          <div
            className={`file_dropzone ${formValues.file && "file_dropzone_active"}`}
            onDrop={(e) => {
              e.preventDefault();
              // UPDATE FILE
              const files = e.dataTransfer.files;
              setFiles(files[0]);
              const fileArray = Array.from(files).map((file: any) =>
                URL.createObjectURL(file)
              );
              setFormValues({
                ...formValues,
                file: fileArray[0],
              });
              e.currentTarget.classList.remove("dropzone_active");
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.currentTarget.classList.add("dropzone_active");
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              e.currentTarget.classList.remove("dropzone_active");
            }}
          >
            {formValues.file && <span>{files.name}</span>}

            <label
              id="dropFileDiv"
              className={formValues.file && "showOnlyOnHover"}
            >

                <AttachFileOutlinedIcon />
                <span>Arrastra o da click para subir un .xlsx</span>
                <input
                  type="file"
                  multiple
                  hidden
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files) {
                      // UPDATE FILE
                      setFiles(files[0]);
                      const fileArray = Array.from(files).map((file: any) =>
                        URL.createObjectURL(file)
                      );
                      setFormValues({
                        ...formValues,
                        file: fileArray[0],
                      });
                    }
                  }}
                />
            </label>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            startIcon={<CloudUploadOutlinedIcon />}
          >
            Subir
          </Button>

        </Box>
      </div>

      <ImportPreviewer 
        data={data}
        open={openViewer}
        setOpen={setOpenViewer}
        upload_type={formValues.upload_type}
        setLoading={setLoading}
        
      />

    </div>
  );
}
