import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";
import { ApiRequest } from "GlobalFunctions";

export default function PaymentMethodsEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, payment_method_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    payment_method_id: payment_method_id ? payment_method_id : "null",
    payment_method: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (payment_method_id) {
      axios
        .get(`${ProjectConfig.api_url}/payment_methods/details`, {
          params: { payment_method_id },
        })
        .then((response) => {
          setFormValues(response.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      setFormValues(defaultValues);
    }
  }, [payment_method_id]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));

    await ApiRequest({
      url: "/payment_methods/addedit",
      method: "post",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar("Método de pago guardado con éxito", { variant: "success" });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
      <div className="DialogContainer" style={{ maxWidth: "502px" }}>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Método de Pago</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">
            <TextField
              id="payment_method"
              name="payment_method"
              label="Método de Pago"
              type="text"
              required
              fullWidth
              value={formValues.payment_method}
              onChange={handleInputChange}
            />
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
