import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {ProjectConfig} from 'Global';
import AutocompleteField from "elements/AutocompleteField";
import { ApiRequest } from "GlobalFunctions";

export default function NotificationsEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    loadList
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    notification_id: "null",
    users: [],
    title: "",
    body: "",
  };
  
  const [formValues, setFormValues] = useState(defaultValues);

  

  const handleInputChange = (e: { target: { name: any; value: any; } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=============================================
  SEND NOTIFICATION
  =============================================*/
  const handleSubmit = async () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    

    await ApiRequest({
      url: "/notifications/send",
      method: "post",
      headers: {
        "ltkn": localStorage.getItem('ltkn')
      },
      body: body,
      setResponse: (response: any) => {
        if(response.status === "success"){
          enqueueSnackbar('Mensaje enviado', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          console.log("error")
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
        setFormValues(defaultValues);
      }
    });
  };

  return (
    <>
      <Dialog 
        open={openEditor}
        onClose={() => {
          setOpenEditor(false)
          setFormValues(defaultValues)
        }}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <div className="DialogContainer" style={{ minWidth: '600px' }}>
            <div className="ModuleWrapper">
                <div className="boxContainerHeader">
                    <div className="boxContainerTitle">
                        <h2 className="h2_title">Mensaje</h2>
                        <span className="h2_subtitle">Editar</span>
                    </div>
                </div>

                <div className="input_group">

                <AutocompleteField 
                  label="Usuario"
                  multiple={true}
                  endpoint="/users/list"
                  value={formValues.users}
                  onChange={(data: any) => {
                    console.log("data", data);
                      setFormValues({...formValues, users: data ? data : []});
                  }}
                />


                  <TextField
                    id="title"
                    name="title"
                    label="Asunto"
                    type="text"
                    required
                    fullWidth
                    value={formValues.title}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="body"
                    name="body"
                    label="Cuerpo"
                    multiline
                    rows={4}
                    type="text"
                    required
                    fullWidth
                    value={formValues.body}
                    onChange={handleInputChange}
                  />




                </div>
            </div>
        </div>

        <div className="dialogButtons">
          <Button
              onClick={() => setOpenEditor(false)}
              variant="outlined"
              className="button_2"
          >
            Cancelar
          </Button>

          <Button onClick={handleSubmit} variant="contained" className="button_1">Enviar</Button>
        </div>
      </Dialog>
    </>
  );
}
