import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";



export default function PlanEditor(props : any) {

  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    plan_id,
    loadList
  } = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */



  const defaultValues: any = {
    plan_id: plan_id? plan_id : "null",
    plan_name: "",
    devices_quantity: 1,
    modules: [],
  };
  
  const [formValues, setFormValues] = useState(defaultValues)


  /*=======================================
  GET USER DETAILS IF USER_ID EXISTS
  =======================================*/
  useEffect(() => {
    if (plan_id) {
      axios.get(ProjectConfig.api_url + "/plans/details", {
          params: {
            plan_id: plan_id,
          },
        })
        .then((response) => {
          console.log("formValues res", response.data)
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setFormValues(defaultValues);
    }
    
  } , [plan_id])




  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);
    
    axios.post(ProjectConfig.api_url+"/plans/addedit", body,
      {
        headers: {
          // 
        },
      })
      .then((response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Plan guardado con éxito', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
      }).
      finally(() => {
        setLoading(false);
      });  
  };






  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="DialogContainer" style={{maxWidth:'502px'}}>
              <div className="ModuleWrapper">
                  <div className="boxContainerHeader">
                      <div className="boxContainerTitle">
                          <h2 className="h2_title">Plan</h2>
                          <span className="h2_subtitle">Editar</span>
                      </div>

                      
                  </div>

                  
                      
                
                <div className='input_group'>
                    <div className='input_row'>

                    <TextField
                      id="plan_name"
                      name="plan_name"
                      label="Nombre"
                      type="text"
                      required
                      fullWidth
                      value={formValues.plan_name}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="devices_quantity"
                      name="devices_quantity"
                      label="Dispositivos"
                      type="number"
                      required
                      fullWidth
                      value={formValues.devices_quantity}
                      onChange={handleInputChange}
                    />
                  </div>

                  <h2>Módulos activos</h2>


                  <div className='switch_row'>
                  <ISwitch
                    label="Inventario"
                    checked={formValues.modules.includes(1)}
                    name="modules"
                    onChange={(e: any) => {
                      const isInventarioChecked = formValues.modules.includes(1); // Aquí 1 representa el module_id del módulo "Inventario"
                      if (isInventarioChecked) {
                        setFormValues({
                          ...formValues,
                          modules: formValues.modules.filter((moduleId: number) => moduleId !== 1) // Filtramos el module_id del módulo "Inventario"
                        });
                      } else {
                        setFormValues({
                          ...formValues,
                          modules: [...formValues.modules, 1] // Agregamos el module_id del módulo "Inventario"
                        });
                      }
                    }} 
                  />

                    <ISwitch
                      label="Pedidos"
                      checked={formValues.modules.includes(2)}
                      name="modules"
                      onChange={(e: any) => {
                        const isPedidosChecked = formValues.modules.includes(2);
                        if (isPedidosChecked) {
                          setFormValues({
                            ...formValues,
                            modules: formValues.modules.filter((moduleId: number) => moduleId !== 2)
                          });
                        } else {
                          setFormValues({
                            ...formValues,
                            modules: [...formValues.modules, 2]
                          });
                        }
                      }}
                    />


                    <ISwitch
                      label="Rutas"
                      checked={formValues.modules.includes(3)}
                      name="modules"
                      onChange={(e: any) => {
                        const isRutasChecked = formValues.modules.includes(3);
                        if (isRutasChecked) {
                          setFormValues({
                            ...formValues,
                            modules: formValues.modules.filter((moduleId: number) => moduleId !== 3)
                          });
                        } else {
                          setFormValues({
                            ...formValues,
                            modules: [...formValues.modules, 3]
                          });
                        }
                      }}
                    />

                    <ISwitch
                      label="Cartera"
                      checked={formValues.modules.includes(4)}
                      name="modules"
                      onChange={(e: any) => {
                        const isCarteraChecked = formValues.modules.includes(4);
                        if (isCarteraChecked) {
                          setFormValues({
                            ...formValues,
                            modules: formValues.modules.filter((moduleId: number) => moduleId !== 4)
                          });
                        } else {
                          setFormValues({
                            ...formValues,
                            modules: [...formValues.modules, 4]
                          });
                        }
                      }}
                    />
                  </div>

                </div>
              
              </div>
          </div>

          <div className="dialogButtons">
            <Button
                onClick={() => setOpenEditor(false)}
                variant="outlined"
                className="button_2"
            >
              Cancelar
            </Button>

            <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
          </div>

        </Dialog>
      </>
  );
}
