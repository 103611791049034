import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";
import AutocompleteSuppliers from "elements/AutocompleteSuppliers";
import AutocompleteWarehouses from "elements/AutocompleteWarehouses";
import AutocompleteParentProducts from "elements/AutocompleteParentProducts";

export default function ParentEditor(props : any) {
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    parent_product_id,
    parent_product_name,
    setParentProduct,
    loadList
  } = props;

  console.log("parent_product_name", parent_product_name);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const defaultValues: any = {
    parent_product_id: parent_product_id ? parent_product_id : "null",
    parent_product_name: parent_product_name ? parent_product_name : "",
    parent_product_code: "",
    supplier_id: "",
    parent_product_base_price: "",
    parent_product_tax: "",
  };
  
  const [formValues, setFormValues] = useState(defaultValues)

  useEffect(() => {
    console.log("parent_product_id", parent_product_id);
    if (parent_product_id) {
      axios.get(ProjectConfig.api_url + "/parent_products/details", {
          params: {
            parent_product_id: parent_product_id,
          },
        })
        .then((response) => {
          console.log("Product details", response.data)
          setFormValues(response.data.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setFormValues(defaultValues);
    }
    
  } , [parent_product_id])


  useEffect(() => {
      setFormValues({
        ...formValues,
        parent_product_name: parent_product_name,
      });
    
  }, [parent_product_name])


  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };




  /*=======================================
  HANDLE SUBMIT
  =======================================*/
  const handleSubmit = () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);
    
    axios.post(ProjectConfig.api_url+"/product_parents/addedit", body,
      {
        headers: {
          "ltkn": localStorage.getItem("ltkn"),
        },
      })
      .then(async (response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Producto padre creado', { variant: 'success' });
          setOpenEditor(false);
          await loadList();
          setParentProduct({parent_product_name: formValues.parent_product_name, parent_product_id: response.data.parent_product_id});
          
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
      }).
      finally(() => {
        setLoading(false);
      });  
  };

  console.log("openEditor", openEditor);

  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="DialogContainer" style={{minWidth:'400px'}}>
              <div className="ModuleWrapper">
                  <div className="boxContainerHeader">
                      <div className="boxContainerTitle">
                          <h2 className="h2_title">Producto Padre</h2>
                          <span className="h2_subtitle">Editar</span>
                      </div>
                  </div>

                  <div className="input_group">

                    <TextField
                      id="parent_product_name"
                      name="parent_product_name"
                      label="Nombre del Producto"
                      type="text"
                      required
                      fullWidth
                      value={formValues.parent_product_name}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="parent_product_code"
                      name="parent_product_code"
                      label="Código del Producto"
                      type="text"
                      required
                      fullWidth
                      value={formValues.parent_product_code}
                      onChange={handleInputChange}
                    />

                    <AutocompleteSuppliers
                      value={formValues.supplier_id}
                      onChange={(e: any) => {
                        setFormValues({
                          ...formValues,
                          supplier_id: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      id="parent_product_base_price"
                      name="parent_product_base_price"
                      label="Precio Base"
                      type="number"
                      required
                      fullWidth
                      value={formValues.parent_product_base_price}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="parent_product_tax"
                      name="parent_product_tax"
                      label="Impuesto"
                      type="number"
                      required
                      fullWidth
                      value={formValues.parent_product_tax}
                      onChange={handleInputChange}
                    />

                    
                  </div>
                </div>
              </div>
         

          <div className="dialogButtons">
            <Button
                onClick={() => setOpenEditor(false)}
                variant="outlined"
                className="button_2"
            >
              Cancelar
            </Button>

            <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
          </div>

        </Dialog>
      </>
  );
}
