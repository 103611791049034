import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';
import { LinearProgress } from '@mui/material';

const cookies = new Cookies();

interface IAutocompleteList {
  name: string;
  id: number;
}



export default function AutocompleteCities(props: any) {

  const {value, onChange, error} = props;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpenList = () => {
  setOpen(true);
  };





  /*=================================================
    CLIENT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IProjectList {
      inputValue?: string,
      city_id?: number,
      city_name: string,
    };
    const filter = createFilterOptions<IProjectList>();

    //setClientList
    const [citylist, setcitylist] = useState<any []>([]);
    const [city, setCity] = useState<IProjectList | null>(null);
    
    useEffect(() => {
      
      axios.get(ProjectConfig.api_url + "/cities/systemList", 
      {
        params: {
          full_list: true,
        }
      })
        .then((response) => {
          setcitylist(response.data.list);
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
    }, []);
    /* ================================================= 
    ===================================================*/


    let item_name = "";
    if (citylist && (value && value!=0)) 
    {
      
      item_name = citylist.find(obj => {
        return obj.city_id === parseInt(value); 
      })
    } 
    
    
  return (
    <>
      {citylist.length > 0 ? (
        <Autocomplete
          value={item_name}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setCity({
                city_name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setCity({
                city_name: newValue.inputValue,
              });
            } else {
              setCity(newValue);
              if (newValue?.city_id!=null)
              {
                onChange({
                    target: {
                        value: newValue.city_id.toString(),
                        name: "city_id",
                    }
                })
              }
              else {
                onChange({
                  target: {
                      value: "",
                      name: "city_id",
                  }
                })
              }
              
            }
            
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          autoComplete={false}
          fullWidth
          id="free-solo-with-text-demo"
          options={citylist}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.city_name;
          }}
          renderOption={(props, option) => <li className="flex_select" {...props}>
            <div className="flex_option_1">{option.city_name}</div>
          </li>}
          freeSolo
          renderInput={(params) => (
            <TextField 
            {...params} 
            label="Ciudad" 
            error={error}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            
            placeholder='Ciudad'
            />
          )}
          /> 
        )
        :
        (
          <>
          
          <LinearProgress />
          
          </>
        )
      }
        
      
    </>
  );
}
