import { Button, Dialog, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {ProjectConfig} from 'Global';
import AutocompleteField from "elements/AutocompleteField";
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Popup from "elements/Popup";

const defaultValues: any = {
  notification: {},
  users: [],
};

export default function NotificationsViewer(props: any) {
  const {
    open,
    setOpen,
    notification_id,
    loadList
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(defaultValues);

  useEffect(() => {
    if(notification_id !== null){
      setLoading(true);
      axios.get(`${ProjectConfig.api_url}/notifications/details`, {
        params: { notification_id },
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [notification_id]);

  return (
    <>
      <Popup 
        open={open}
        onClose={() => {
          setOpen(false)
          setData(defaultValues)
        }}
        loading={loading}
      >
        
        <div className="DialogContainer" style={{ minWidth: '600px' }}>
            <div className="ModuleWrapper">
                <div className="boxContainerHeader">
                    <div className="boxContainerTitle">
                        <h2 className="h2_title">{data.notification.title}</h2>
                        <span className="h2_subtitle">{data.notification.created_at}</span>
                    </div>
                </div>
                         
                <p>{data.notification.body}</p>
                
                <h2 className="h2_title">Usuarios</h2>
                <Table>
                  <TableRow>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Usuario</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}></TableCell>
                  </TableRow>
                  <TableBody>
                    {data.users.map((user: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{user.name} {user.last_name}</TableCell>
                        <TableCell align="right">{user.is_read === 1 ? 
                            <Tooltip title={`Leído el ${user.read_date}`} arrow> 
                              <MarkEmailReadIcon sx={{color: "#0BA757"}} />
                            </Tooltip>
                            : 
                            <Tooltip title="No leído" arrow> 
                              <EmailIcon sx={{color:"#cccccc"}} />
                            </Tooltip>
                          }</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
            </div>
        </div>
        

        <div className="dialogButtons">
          <Button
              onClick={() => setOpen(false)}
              variant="outlined"
              className="button_2"
          >
            Cerrar
          </Button>

        </div>
      </Popup>
    </>
  );
}
