import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  TablePagination,
  DialogActions
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState, useRef } from "react";
import AlertDialog from "elements/AlertDialog";
import axios from "axios";
import { ProjectConfig } from "Global";
import LoadingCircle from "elements/LoadingCircle";

export default function ImportPreviewer(props: any) {
  const {open, setOpen, data, upload_type } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState<any>({ headers: [], rows: [] });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const textFieldRefs = useRef<any[]>([]);

  useEffect(() => {
    setFormValues(data);
  }, [data]);

  const handleEdit = (index: number) => {
    setEditIndex(index);
  };

  const handleSave = () => {
    const updatedRows = [...formValues.rows];
    const inputs = textFieldRefs.current[editIndex!];

    formValues.headers.forEach((header: string, colIndex: number) => {
      if (inputs[colIndex]) {
        updatedRows[editIndex!][header] = inputs[colIndex].value;
      }
    });

    setFormValues({ ...formValues, rows: updatedRows });
    setEditIndex(null);
    enqueueSnackbar("Changes saved successfully", { variant: "success" });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openAlert, setOpenAlert] = useState(false);



  /*=======================================
  SUBMIT
  =======================================*/
  const handleSubmit = () => {
    setLoading(true);
    console.log(formValues);
    const formData = new FormData();
    //add upload_type to formValues
    formValues.upload_type = upload_type;
    formData.append("formValues", JSON.stringify(formValues));
    
    let delegated_company_id = null;
    if(localStorage.getItem('delegated_company_id')){
      delegated_company_id = localStorage.getItem('delegated_company_id')!;
    }

    //AXIOS
    axios
      .post(ProjectConfig.api_url + "/sync/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "ltkn": localStorage.getItem("ltkn"),
          delegated_company_id
        },
      })
      .then((response) => {
        console.log("res submit import ",response.data);
        if (response.data.status === "success") {
          enqueueSnackbar(`${response.data.importedQuantity} registros importados`, { variant: "success" });
          setOpen(false);
        } else {
          enqueueSnackbar("Error al sincronizar", { variant: "error" });
        }
        
      })
      .catch((err) => {
        
        console.log(err.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  



  return (
    <>
    
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth={true}
        maxWidth={false}
        
      >
        <LoadingCircle loading={loading} className="innerLoader" />
        <div className="DialogContainer" style={{ width: 'auto' }}>
          <div className="ModuleWrapper">
            <div className="boxContainerHeader">
              <div className="boxContainerTitle">
                <h2 className="h2_title">Previsualizar importe</h2>
              </div>
            </div>

            <div className="content">
              {formValues && formValues.headers && formValues.rows && formValues.rows.length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <Table id="importPreviewTable">
                      <TableHead>
                        <TableRow>
                          {formValues.headers.map((header: string) => (
                            <TableCell key={header}>{header}</TableCell>
                          ))}
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formValues.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: number) => (
                          <TableRow key={index + page * rowsPerPage}>
                            {formValues.headers.map((header: string, colIndex: number) => (
                              <TableCell key={header}>
                                {editIndex === index + page * rowsPerPage ? (
                                  <TextField
                                    defaultValue={row[header]}
                                    size="small"
                                    inputRef={(el) => {
                                      if (!textFieldRefs.current[index + page * rowsPerPage]) {
                                        textFieldRefs.current[index + page * rowsPerPage] = [];
                                      }
                                      textFieldRefs.current[index + page * rowsPerPage][colIndex] = el;
                                    }}
                                    fullWidth
                                  />
                                ) : (
                                  row[header]
                                )}
                              </TableCell>
                            ))}
                            <TableCell>
                              {editIndex === index + page * rowsPerPage ? (
                                <Button
                                  onClick={handleSave}
                                  variant="contained"
                                  color="primary"
                                >
                                  Guardar
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => handleEdit(index + page * rowsPerPage)}
                                  variant="outlined"
                                  color="primary"
                                >
                                  Editar
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={formValues.rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Filas por página"
                  />
                </>
              ) : (
                <Typography variant="h6" align="center" style={{ padding: '20px' }}>
                  No hay datos disponibles para mostrar.
                </Typography>
              )}
            </div>
          </div>
        </div>
        <DialogActions
          //fixed
          style={{ position: 'sticky', bottom: 0,  backgroundColor: 'white', padding: '16px' }}
          >
          <Button onClick={() => setOpen(false)} variant="outlined" className="button_2">
            Cancelar
          </Button>
          <Button
            variant="contained"
            className="button_1"
            onClick={() => {
              setOpenAlert(true);
            }}
          >
            Importar
          </Button>
        </DialogActions>
        
        
        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          send={handleSubmit}
          subtitle="¿Estás seguro de realizar esta acción?"
        />

      </Dialog>
    </>
  );
}
