import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";



export default function FiltersProducts(props: any) {



    const {
        open,
        setOpen,
        filters,
        setFilters
    } = props;

    const iconSize = 15;
    
    /*=========================================================
    ORDER BY
    =========================================================*/
    const toggleOrderByFilter = (key: string) => () => {
        setFilters((currentFilters: any[]) => {
            // Remove the current "order_by" filter if it exists
            const nonOrderByFilters = currentFilters?.filter((f: { type: string; }) => f.type !== "order_by");
            const existingOrderBy = currentFilters?.find((f: { type: string; key: any; }) => f.type === "order_by" && f.key === key);
    
            // Toggle the order_by filter or add a new one
            if (existingOrderBy) {
                if (existingOrderBy.order === "ASC") {
                    return [...nonOrderByFilters, { key, order: "DESC", type: "order_by" }];
                }
                // If order is DESC, remove the filter
                return nonOrderByFilters;
            } else {
                return [...nonOrderByFilters, { key, order: "ASC", type: "order_by" }];
            }
        });
    };

    
    const [searchText, setSearchText] = useState("");


    /*=========================================================
    RENDER
    =========================================================*/
    return (
            <div style={{padding: 10}}>

                <div>
                    <div style={{marginRight: 10}}>Ordenar por:</div>

                        <Button 
                            onClick={toggleOrderByFilter("product_name")}>
                            <span>Producto AZ</span>
                        </Button>

                        <Button onClick={toggleOrderByFilter("warehouse_name")}>
                            <span>OR Bodega</span>
                
                        </Button>
                        <Button onClick={toggleOrderByFilter("supplier_name")}>
                            <span>Proveedor</span>
                            
                        </Button>
                        

                        <Button onClick={toggleOrderByFilter("group_id")}>
                            <span>Grupo</span>

                        </Button>

                        <Button onClick={toggleOrderByFilter("category_id")}>
                            <span>Categoría</span>

                        </Button>

                        <Button onClick={toggleOrderByFilter("brand_id")}>
                            <span>Marca</span>
                            
                        </Button>
                </div>

                <div className="flex_row">
                    <TextField 
                        label="Buscar"
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                        fullWidth
                    />

                    <Button 
                        variant="contained"
                        onClick={() => {
                        const value = searchText;
                        setFilters((currentFilters: any[]) => {
                            const nonSearchFilters = currentFilters.filter((f: { type: string; }) => f.type !== "search");
                            if (value) {
                                return [...nonSearchFilters, {key: "product", value, type: "search" }];
                            }
                            return nonSearchFilters;
                        });
                    } 
                    }>
                        
                        Buscar
                    </Button>
                </div>


               
            
                
            </div>
    );
}





