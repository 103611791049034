import React from 'react';
import { Bar } from 'react-chartjs-2';
import Box from 'elements/Box';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';

// Registrar los componentes necesarios de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// Definir la interfaz para los datos de los recolectores
interface CollectorData {
  user_id: number;
  user_name: string;
  total_collected: number;
}

// Definir los tipos de datos para el gráfico
type DataType = ChartData<'bar', number[], string>;
type OptionsType = ChartOptions<'bar'>;

// Función auxiliar para asignar colores a cada usuario
function getColor(index: number): string {
  const colors = [
    'rgba(0, 128, 0, 0.8)',   // Verde (Ventas)
    'rgba(0, 119, 182, 0.8)', // Azul (Peticiones)
    'rgba(255, 206, 86, 0.8)', // Amarillo (Reclamos)
    'rgba(255, 0, 0, 0.8)',   // Rojo (Felicitación)
    'rgba(128, 0, 128, 0.8)', // Morado (Soporte)
  ];
  return colors[index % colors.length];
}

export default function DailyCollectors(props: any) {
  const { data } = props;

  // Ordenar los datos para que el que más recolectó esté arriba
  const sortedData = data.sort((a: CollectorData, b: CollectorData) => a.total_collected - b.total_collected);

  // Preparar los datos para el gráfico
  const chartData: DataType = {
    labels: ['Hoy'], // Mantener una sola etiqueta para el eje X
    datasets: sortedData.map((collector: CollectorData, index: number) => ({
      label: collector.user_name, // Colocar el nombre del recolector como etiqueta
      data: [collector.total_collected], // Colocar el total recolectado
      backgroundColor: getColor(index),
    })),
  };

  // Opciones de configuración del gráfico para ocupar todo el espacio del contenedor
  const options: OptionsType = {
    responsive: true,
    maintainAspectRatio: false, // Permitir que el gráfico ocupe el 100% del contenedor
    plugins: {
      legend: {
        display: true, // Mostrar la leyenda
        position: 'bottom', // Colocar la leyenda debajo de la gráfica
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        display: true, // Mostrar el eje X
        grid: {
          display: false, // Ocultar las líneas de la cuadrícula del eje X
        },
      },
      y: {
        beginAtZero: true,
        display: true, // Mostrar el eje Y
        grid: {
          display: true, // Mostrar las líneas de la cuadrícula del eje Y
        },
      },
    },
  };

  return (
    <Box title="Recaudo del día" style={{ height: '420px' }}>
      <div style={{ width: '100%', height: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </Box>
  );
}
