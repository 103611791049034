import * as React from 'react';
import { useState } from 'react';
import Box from 'elements/Box';

export default function TopDebtByUser(props: any) {

  const { data } = props;

  return (
    <Box title="Top deuda por asesor">
      <div className="topDebtByUser">
        {data.map((item: any, index: any) => (
          <div className="topDebtByUser_item" key={index}>
            <div className="topDebtByUser_name">
              {item.user_name}
            </div>
            <div className="topDebtByUser_value">
              ${item.total_cartera.toLocaleString()}
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
}
