import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import Box from 'elements/Box';

export default function DebtAges(props: any) {
  const { data } = props;

  return (
    <Box title="Cartera por edades">
      <div style={{ width: '100%', height: '350px' }}>
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: data.total_30_days, label: '30 Días' },
                { id: 1, value: data.total_60_days, label: '60 Días' },
                { id: 2, value: data.total_90_days, label: '90 Días' },
                { id: 3, value: data.total_90_plus_days, label: 'Más de 90 Días' },
              ],
              innerRadius: 30,
              outerRadius: 100,
              paddingAngle: 5,
              cornerRadius: 5,
              startAngle: -45,
              endAngle: 360,
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: 0,
            },
          }}
        />
      </div>
    </Box>
  );
}
