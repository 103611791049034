import { ApiRequest } from "GlobalFunctions";
import TotalDebt from "./components/TotalDebt"
import { useEffect, useState } from "react";
import DebtAges from "./components/DebtAges";
import DebtByUser from "./components/DebtByUser";
import TopDebtByUser from "./components/TopDebtByUser";
export default function Debt(props: any) {

    const { setLoading } = props
    const [dashboardData, setDashboardData] = useState({
        total_debt: {
            total_debt: 0,
            total_debt_overdue: 0,
            overdue_percentage: 0,
        },
        top_debt_by_user: [],
        debt_by_age: {
            total_30_days: 0,
            total_60_days: 0,
            total_90_days: 0,
            total_90_plus_days: 0,
        },
        todays_debt_collected:[]
    })

    useEffect(() => {
        getDebtDashboard()
    }, [])


    const getDebtDashboard = async () => {
        

        await ApiRequest({
            url: "/dashboard/getDebtDashboard",
            method: "get",
            headers: {
              'ltkn': localStorage.getItem('ltkn') 
            },
            setResponse: (response: any) => {
              setDashboardData(response)
              setLoading(false)
            }
          })
    }

    return (
        <div id="Dashboard" className="ModuleContainer">
            <div className="ModuleWrapper">
                <div className="boxContainerHeader">
                    <div className="boxContainerTitle">
                    <h1>Cartera</h1>
                    </div>
        
                    <div className="boxContainerActions">
                    
                    </div>
                </div>
        
                <div id="DashboardIn">
                <TotalDebt data={dashboardData.total_debt} />
                    <div className="dashboard_row">
                        <TopDebtByUser data={dashboardData.top_debt_by_user} />
                    </div>
                    <div className="dashboard_row">
                        <DebtAges data={dashboardData.debt_by_age} />
                        <DebtByUser data={dashboardData.todays_debt_collected} />
                    </div>

                </div>
            </div>
        </div>
    )

}