import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ProjectConfig } from "Global";
import Box from "elements/Box";
import Cookies from "universal-cookie";
import { Button } from "@mui/material";
import SupplierProducts from "./components/SupplierProducts";
import UsersCount from "./components/UsersCount";
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import BrandProducts from "./components/BrandProducts";
import { ApiRequest } from "GlobalFunctions";

export default function Home(props: any) {
  const { setLoading } = props;

  
  /*=============================================
  GET DASHBOARD DATA
  =============================================*/
  const [dashboardData, setDashboardData] = useState({
    usersCount: {} as any,
    supplierProducts: [] as any,
    lastInventoryCount: "0000-00-00",
    inventoryValue: 0
  });

  const [switchSupplierBrand, setSwitchSupplierBrand] = useState("supplier");


  const getDashboardData = async () => {
    setLoading(true);

    await ApiRequest({
      url: "/dashboard/getCompanyDashboard",
      method: "get",
      headers: {
        'ltkn': localStorage.getItem('ltkn') 
      },
      setResponse: (response: any) => {
        setDashboardData(response)
        setLoading(false)
      }
    })

  };

  useEffect(() => {
    getDashboardData();
  }, []);

  
  return (
    <div id="Dashboard" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h1>Inventario</h1>
          </div>

          <div className="boxContainerActions">
           
          </div>
        </div>

        <div id="DashboardIn">

          <div className="dashboard_row">
          <div id='lastInventoryCount' className="boxIndicator">
              <div className="boxIndicatorContent">

                <CalendarMonthRoundedIcon fontSize="large" />
                <div className="boxIndicatorContentInner">
                  <h2>ÚLTIMO CONTEO DE INVENTARIO</h2>
                  <span className="indicatorValue">{dashboardData.lastInventoryCount}</span>
                </div>
                
              </div>
            </div>

            <div id='inventoryValue' className="boxIndicator">
              <div className="boxIndicatorContent">

                <WarehouseRoundedIcon fontSize="large" />
                <div className="boxIndicatorContentInner">
                  <h2>VALOR TOTAL DE INVENTARIO</h2>
                  <span className="indicatorValue">${Number(dashboardData.inventoryValue).toLocaleString()}</span>
                </div>
                
              </div>
            </div>
          </div>


          <div className="dashboard_row">
            <Box title="Estado de conteo">
                <UsersCount chartData={dashboardData.usersCount} />
            </Box>

            {switchSupplierBrand === "supplier" &&
              <Box title="PROVEEDORES">
                  <Button onClick={() => setSwitchSupplierBrand("brand")}>Cambiar a Marcas</Button>
                  <SupplierProducts />
              </Box>
            }

            {switchSupplierBrand === "brand" &&
              <Box title="Marcas">
                  <Button onClick={() => setSwitchSupplierBrand("supplier")}>Cambiar a Proveedores</Button>
                  <BrandProducts />
              </Box>
            }
          </div>


        </div>
      </div>

    </div>
  );
}
