let ProjectConfigOverride = {};
try {
  ProjectConfigOverride = require('./GlobalOverride').ProjectConfigOverride;
} catch (error) {
  ProjectConfigOverride = {};
}

export const ProjectConfig = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? {
  api_url: "http://localhost:3001",
  ...ProjectConfigOverride
} : {
  api_url: "https://connect3sapi.osabana.com",
  ...ProjectConfigOverride
};
