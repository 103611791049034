import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {ProjectConfig} from 'Global';
import { ApiRequest } from "GlobalFunctions";

export default function BrandEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, brand_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    brand_id: brand_id ? brand_id : "null",
    brand_name: "",
    brand_code: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (brand_id) {
      axios.get(`${ProjectConfig.api_url}/brands/details`, {
        params: { brand_id },
      })
      .then((response) => {
        setFormValues(response.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
    } else {
      setFormValues(defaultValues);
    }
  }, [brand_id]);

  const handleInputChange = (e: { target: { name: any; value: any }; }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    
    await ApiRequest({
      url: "/brands/addedit",
      method: "post",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      setResponse: (response: any) => {
        if(response.status === "success"){
          enqueueSnackbar('Marca guardada con éxito', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
    }); 
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
      <div className="DialogContainer" style={{ maxWidth: '502px' }}>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Marca</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">
            <TextField
              id="brand_name"
              name="brand_name"
              label="Nombre de la Marca"
              type="text"
              required
              fullWidth
              value={formValues.brand_name}
              onChange={handleInputChange}
            />

            <TextField
              id="brand_code"
              name="brand_code"
              label="Código de la Marca"
              type="text"
              required
              fullWidth
              value={formValues.brand_code}
              onChange={handleInputChange}
            />
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">Cancelar</Button>
            <Button onClick={handleSubmit} variant="contained">Guardar</Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
