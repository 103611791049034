import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";



export default function AdminEditor(props : any) {

  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    user_id,
    loadList
  } = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */



  const defaultValues: any = {
    user_id: user_id? user_id : "null",
    name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    is_active: true,
    role_id: 1, //administrator
  };
  
  const [formValues, setFormValues] = useState(defaultValues)


  /*=======================================
  GET USER DETAILS IF USER_ID EXISTS
  =======================================*/
  useEffect(() => {
    if (user_id) {
      axios.get(ProjectConfig.api_url + "/users/details", {
          params: {
            user_id: user_id,
          },
        })
        .then((response) => {
          console.log("formValues res", response.data)
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setFormValues(defaultValues);
    }
    
  } , [user_id])




  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);


    axios.post(ProjectConfig.api_url+"/users/addedit", body,
      {
        headers: {
          // 
        },
      })
      .then((response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Administrador guardado con éxito', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
      }).
      finally(() => {
        setLoading(false);
      });  
  };






  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="DialogContainer" style={{maxWidth:'502px'}}>
              <div className="ModuleWrapper">
                  <div className="boxContainerHeader">
                      <div className="boxContainerTitle">
                          <h2 className="h2_title">Administrador</h2>
                          <span className="h2_subtitle">Editar</span>
                      </div>

                      
                  </div>

                  <div id="userForm">
                    <div className="userForm_right">
                      
                        <div className='input_group'>
                            <h2>Información Personal</h2>
                            <div className='input_group'>
                                <div className='input_row'>
                                <TextField 
                                  id="name"
                                  name="name"
                                  label="Nombres"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.name}
                                  onChange={handleInputChange}
                                />

                                <TextField
                                  id="last_name"
                                  name="last_name"
                                  label="Apellidos"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.last_name}
                                  onChange={handleInputChange}
                                />
                              </div>

                              <TextField
                                id="phone"
                                name="phone"
                                label="Teléfono"
                                type="text"
                                required
                                fullWidth
                                value={formValues.phone}
                                onChange={handleInputChange}
                              />

                            </div>

                            <div className='input_group'>
                              <h2>Información de sesión</h2>
                              <div className="input_row">
                                <TextField
                                  id="email"
                                  name="email"
                                  label="Email"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.email}
                                  onChange={handleInputChange}
                                />

                                <TextField
                                  id="password"
                                  name="password"
                                  label="Password"
                                  type="password"
                                  autoComplete="new-password"
                                  required
                                  fullWidth
                                  value={formValues.password}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>


                      
                          <ISwitch
                            label="Activo"
                            checked={formValues.is_active}
                            name="is_active"
                            onChange={(e: any) => {
                              setFormValues({
                                ...formValues,
                                ["is_active"]: e.target.checked
                              });
                            }}
                          />


                        </div>


                      
                    </div>


                    

                </div>
              </div>
          </div>

          <div className="dialogButtons">
            <Button
                onClick={() => setOpenEditor(false)}
                variant="outlined"
                className="button_2"
            >
              Cancelar
            </Button>

            <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
          </div>

        </Dialog>
      </>
  );
}
