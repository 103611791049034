import { CircularProgress } from '@mui/material';
import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";



interface Props {
  id?:any,
  open:boolean,
  onClose?:any,
  children?: React.ReactNode;
  key?:any,
  maxWidth?:any,
  fullWidth?:any,
  className?: string,
  style?:any,
  loading?:boolean,
}    

export default function Popup (props: Props) {

  const {
    loading = false,
  } = props;

  const className = props.className ? " "+props.className : "";
  const id = props.id ? props.id : "";
  const style = props.style ? props.style : {};

  const open = props.open ? props.open : false;
  const fullWidth = props.fullWidth ? props.fullWidth : false;
  const maxWidth = props.maxWidth ? props.maxWidth : "md";
  const onClose = props.onClose ? props.onClose : null;

  return (
    
  <Dialog 
    open={open}
    onClose={onClose}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
  >
    <div style={loading ? {position: 'relative', overflow: 'hidden'} : {}}>
      <div id="InnerLoader" className={loading ? "InnerLoader" : "InnerLoader hidden"}>
        <CircularProgress />
      </div>
      {props.children}
    </div>
  </Dialog>
  );
};

