import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { ApiRequest } from 'GlobalFunctions';
import debounce from 'lodash/debounce';

export default function AutocompleteField(props: any) {
  const {
    endpoint = "",
    endpoint_params = {},
    label,
    value,
    onChange,
    fullwidth = false,
    dynamic = false,
    max_results = null,
    multiple = false,
  } = props;

  const [list, setList] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>(multiple ? [] : null);

  useEffect(() => {
    getList();
  }, [JSON.stringify(endpoint_params)]);

  /*=========================================================
    GET LIST
  =========================================================*/
  const getList = async (limit: number | null = null, inputValue: string = "") => {
    await ApiRequest({
      url: endpoint,
      method: "get",
      headers: {
        ltkn: localStorage.getItem('ltkn'),
      },
      query: {
        full_list: limit === null,
        max_results: limit || undefined,
        ...endpoint_params,
        filters: (dynamic && [{ key: 'name', value: inputValue, action: 'search', condition: '=' }]) || undefined,
      },
      setResponse: (response: any) => {
        if (response.list.length > 0) {
          setList(response.list);
        } else if (inputValue === "") {
          setList([]);  // Solo limpia si el campo está vacío
        }
      },
    });
  };

  // Debounced function to fetch limited results based on max_results
  const debouncedGetList = debounce((limit: number, inputValue: string) => {
    getList(limit, inputValue);
  }, 500);

  /*=========================================================
    HANDLE CHANGE IN AUTOCOMPLETE INPUT
  =========================================================*/
  const handleInputChange = (event: any, newInputValue: string) => {
    if (dynamic) {
      if (newInputValue !== "") {
        debouncedGetList(max_results, newInputValue);
      }
    } else if (newInputValue && max_results) {
      debouncedGetList(max_results, "");
    }
  };

  /*=========================================================
    UPDATE SELECTED ITEMS
  =========================================================*/
  useEffect(() => {
    if (!multiple) {
      if (list && value && value !== 0) {
        const item = list.find((obj: any) => obj.value === parseInt(value));
        if (item) {
          setSelectedItems(item);
        }
      }
    } else {
      if (list && value && Array.isArray(value)) {
        const items = list.filter((obj: any) => value.includes(obj.value));
        setSelectedItems(items);
      }
    }
  }, [list, value, multiple]);

  /*=========================================================
    RETURN
  =========================================================*/
  return (
    <>
      {list && list.length > 0 ? (
        <Autocomplete
          value={selectedItems}
          onChange={(event: any, newValue: any) => {
            if (!multiple) {
              setSelectedItems(newValue);
              onChange(newValue ? newValue : null);
            } else {
              setSelectedItems(newValue);
              onChange(newValue ? newValue.map((item: any) => item.value) : []);
            }
          }}
          multiple={multiple}
          id="autocomplete"
          options={list}
          fullWidth={fullwidth}
          getOptionLabel={(option: any) => option.label}
          renderOption={(props, option) => (
            <li className="flex_select" {...props}>
              <div className="flex_option_1">{option.label}</div>
            </li>
          )}
          renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        />
      ) : (
        <p style={{ color: "red" }}>Sin {label}</p>
      )}
    </>
  );
}
