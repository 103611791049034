import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";
import { ApiRequest } from "GlobalFunctions";

export default function SystemCitiesEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, city_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    city_id: city_id ? city_id : "null",
    city_name: "",
    state_id: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (city_id) {
      axios
        .get(`${ProjectConfig.api_url}/cities/systemDetails`, {
          params: { city_id },
        })
        .then((response) => {
          setFormValues(response.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      setFormValues(defaultValues);
    }
  }, [city_id]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);

    await ApiRequest({
      url: "/cities/systemAddEdit",
      method: "post",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: formValues,
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar("Ciudad guardada con éxito", { variant: "success" });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
      <div className="DialogContainer" style={{ maxWidth: "502px" }}>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Ciudad</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">
            <TextField
              id="city_name"
              name="city_name"
              label="Nombre de la Ciudad"
              type="text"
              required
              fullWidth
              value={formValues.city_name}
              onChange={handleInputChange}
            />
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
