import React, { useEffect, useState } from 'react';
import { ApiRequest } from "GlobalFunctions";
import Box from "elements/Box";
import { Button, Chip, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import TuneIcon from '@mui/icons-material/Tune';
import Filters from "elements/Filters/Filters";
import FiltersApplied from "elements/Filters/FiltersApplied";
import InvoiceListDialog from './InvoiceListDialog'; // Importar el nuevo componente

export default function ClientList(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;

  const [clients, setClients] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const currentPage = parseInt(localStorage.getItem("clientPage") || "1");
  const [page, setPage] = useState(currentPage);

  const [open, setOpen] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [openInvoicesDialog, setOpenInvoicesDialog] = useState(false);

  const fetchClients = async () => {
    setLoading(true);

    await ApiRequest({
      url: "/invoices/clientList",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        page: page,
        filters: filtersValues,
      },
      setResponse: (response: any) => {
        setClients(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      },
    });

    localStorage.setItem("clientPage", page.toString());
  };

  useEffect(() => {
    fetchClients();
  }, [page, filtersValues]);

  const filters = [
    { type: "text", label: "NIT", name: "document", value: "" },
    { type: "text", label: "Nombre", name: "client_name", value: "" },
    { type: "text", label: "Negocio", name: "business_name", value: "" },
  ];

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
    localStorage.setItem('filters', JSON.stringify(tempFilters));
  };

  const handleClientClick = (client: any) => {
    setSelectedClient(client);
    setOpenInvoicesDialog(true); // Abrir el diálogo de facturas
  };

  return (
    <div id="ClientList" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Lista de Clientes</h2>
            <span className="h2_subtitle">Consulta y gestión de clientes</span>
          </div>
          <div className="boxContainerActions">
            {/* Botones adicionales si es necesario */}
          </div>
        </div>

        <IconButton color="inherit" aria-label="menu" onClick={() => setOpen(true)}>
          <TuneIcon />
        </IconButton>
        <Filters open={open} setOpen={setOpen} filters={filters} setFilters={setFilters} />
        <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues} />

        <Box id="ClientListIn" className="box100">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Nombre Completo</TableCell>
                  <TableCell>Negocio</TableCell>
                  <TableCell>Ciudad</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Teléfono</TableCell>
                  <TableCell>Tipología</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map((client, i) => (
                  <TableRow key={i} hover onClick={() => handleClientClick(client)}>
                    <TableCell>{client.client_code}</TableCell>
                    <TableCell>{`${client.client_name} ${client.client_name2} ${client.client_last_name} ${client.client_last_name2}`}</TableCell>
                    <TableCell>{client.business_name}</TableCell>
                    <TableCell>{client.city_name}</TableCell>
                    <TableCell>{client.client_email || "N/A"}</TableCell>
                    <TableCell>{client.client_phone}</TableCell>
                    <TableCell>
                      <Chip label={client.typology_name} color="primary" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
            style={{ marginTop: "20px", alignSelf: "center" }}
          />
        </Box>

        {/* Diálogo para mostrar las facturas del cliente */}
        <InvoiceListDialog
          open={openInvoicesDialog}
          setOpen={setOpenInvoicesDialog}
          client={selectedClient}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
}
