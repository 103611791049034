import axios from 'axios';
import {ProjectConfig} from 'Global';

/*=========================================================
API REQUEST
=========================================================*/
export const ApiRequest = async (params: any) => {

    //if localStorage.getItem('delegated_company_id') is set, add it to the headers
    if(localStorage.getItem('delegated_company_id')){
        params.headers = {
            ...params.headers,
            'delegated_company_id': localStorage.getItem('delegated_company_id')
        }
    }


    const {
        url, 
        method, 
        headers, 
        body = null,
        query = null,
        formData = false,
        setResponse,
    } = params;

    

    // If formData is true, ensure we use FormData and set the correct header
    let data: any = null;
    if (formData && body instanceof FormData) {
        data = body;
        params.headers = {
            ...params.headers,
            'Content-Type': 'multipart/form-data'
        };
    } else {
        data = body ? body : null;
    }


    const request_params = {
        url: ProjectConfig.api_url+url,
        method: method,
        headers: {
            ...headers
        },
        params: {
            ...query
        },
        data
    }

    /*REQUEST
    =========================================================*/
    await axios.request(
        request_params
    )
    .then((response) => {
        console.log(`API REQUEST ${url}`, response.data);
        setResponse(response.data);
    })
    .catch((err) => {
        console.log("error",err)
    })
    .finally(() => {

    })
    

}



/*=========================================================
VALIDATE FORM
=========================================================*/
export const validateForm = (formValues: any, fields:any) => {
    let isValid = true;
    let errors = {} as any;
 
    for (const key in fields) {
        if (!formValues[key]) {
            isValid = false;
            errors[key] = fields[key];
        }
    }

    return {
        isValid,
        errors,
        message: (isValid) ? "Formulario válido" : `Falta completar: ${Object.values(errors).join(", ")}`
    }
}