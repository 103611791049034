import * as React from 'react';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';
import Box from 'elements/Box';

function GaugePointer() {
 

  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="red" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}

export default function TotalDebt(props:any) {
  const {data} = props
  return (
      <div className='totalDebtContainer'>
        
        <div className="DebtIndicator">
          <div className='indicatorValue'>${data.total_debt.toLocaleString()}</div>
          <div className='DebtLabel'>Total Cartera</div>
        </div>

        <GaugeContainer
          width={200}
          height={200}
          startAngle={-110}
          endAngle={110}
          value={data.overdue_percentage}
        >
          <GaugeReferenceArc />
          <GaugeValueArc />
          <GaugePointer />
        </GaugeContainer>

        <div className="DebtIndicator">
          <div className='indicatorValue'>${data.total_debt_overdue.toLocaleString()}</div>
          <div className='DebtLabel'>Cartera Vencida</div>
        </div>
      </div>
  );
}

