import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { colors, LinearProgress } from '@mui/material';
import AutocompleteField from 'elements/AutocompleteField';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { useEffect, useState } from 'react';
import { ApiRequest } from 'GlobalFunctions';

export default function BrandProducts(props: any) {



  const [formValues, setFormValues] = useState({
    brands: [],
  });

  console.log("formValues", formValues);

  const [data, setData] = useState([] as any);

  const getSupplierProducts = async () => {

    await ApiRequest({
      url: "/dashboard/getBrandProducts",
      method: "get",
      headers: {
        'ltkn': localStorage.getItem('ltkn') 
      },
      query: {
        brands: formValues.brands
      },
      setResponse: (response: any) => {
        console.log("brands data ",response); 
        setData(response)
      }
    })

  }

  useEffect(() => {
    getSupplierProducts();
  }, [formValues]);


  return (
    <div className="supplierProducts">
      <AutocompleteField 
        label="Marcas"
        endpoint="/brands/list"
        multiple={true}
        value={formValues.brands}
        onChange={(data: any) => {
            setFormValues({...formValues, brands: data ? data : []});
        }}
      />


      {data.map((item: any, index: any) => (
        <div className="supplierProducts_indicator" key={index}>
          <div className="supplierProducts_bar">
            <LinearProgress variant="determinate" value={item.percentage} sx={{ height: 10, borderRadius: 5, '& .MuiLinearProgress-bar1Determinate': { backgroundColor: item.color } }} />
            <span className="supplierProducts_bar_text">{item.brand_name}</span>
          </div>
          <div className="supplierProducts_indicator_text">
            <div className='supplierProducts_indicator_quantity'>{item.brand_products}</div>
            <div className='supplierProducts_indicator_price'>${item.price.toLocaleString()}</div>
          </div>
        </div>
      ))}
    </div>

    
  );
}


