import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'elements/Box';
import {
  Button,
  Chip,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { ProjectConfig } from 'Global';
import AlertDialog from 'elements/AlertDialog';
import { useSnackbar } from 'notistack';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCloudDownloadOutline } from "react-icons/io5";
import TuneIcon from '@mui/icons-material/Tune';
import Filters from "elements/Filters/Filters";
import FiltersApplied from "elements/Filters/FiltersApplied";

import { ApiRequest } from 'GlobalFunctions';

export default function PaymentsList(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const currentPage = parseInt(localStorage.getItem('paymentsPage') || '1');
  const [page, setPage] = useState(currentPage);
  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);

  /*========================================
  FILTERS
  =========================================*/
  const [open, setOpen] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>([]);
  const filters = [
    { type: "text", label: "NIT", name: "document", value: "" },
    { type: "text", label: "Nombre", name: "client_name", value: "" },
    { type: "text", label: "Negocio", name: "business_name", value: "" },
  ];

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
    localStorage.setItem('filters', JSON.stringify(tempFilters));
  };


  /*========================================
  LOAD LIST
  =========================================*/
  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page,filtersValues]);


  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/payments/list',
      method: 'get',
      headers: {
        ltkn: localStorage.getItem('ltkn'),
      },
      query: {
        page: page,
        filters: filtersValues,
      },
      setResponse: (response: any) => {
        setList(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      },
    });

    localStorage.setItem('paymentsPage', page.toString());
  };

  /*========================================
  VOID PAYMENT
  =========================================*/
  const handleVoidPayment = (payment_id: number) => {
    setActiveItem(payment_id);
    setOpenAlert(true);
  };

  const voidPayment = () => {
    const body = {
      payment_id: activeItem,
    };

    ApiRequest({
      url: '/payments/deleteItem',
      method: 'post',
      headers: {
        ltkn: localStorage.getItem('ltkn'),
      },
      body,
      setResponse: (response: any) => {
        enqueueSnackbar('Recaudo anulado correctamente', { variant: 'success' });
        //reload list
        loadList();
      },
    });
  };


  /*========================================
  EXPORT PAYMENT
  =========================================*/
  const exportPayment = async (payment_id: any) => {

    await ApiRequest({
      url: "/payments/exportPayment",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        payment_id
      },
      setResponse: (response: any) => {
        window.open(response, '_blank');
        setLoading(false);
      },
    });
  }






  return (
    <div id="PaymentsList" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Recaudos</h2>
            <span className="h2_subtitle">Lista de pagos</span>
          </div>
        </div>

        <IconButton color="inherit" aria-label="menu" onClick={() => setOpen(true)}>
          <TuneIcon />
        </IconButton>
        <Filters open={open} setOpen={setOpen} filters={filters} setFilters={setFilters} />
        <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues} />

        <Box id="PaymentsListIn" className="box100">
          <TableContainer>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>
                    N. RECIBO / FECHA
                  </TableCell>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>
                    VALOR
                  </TableCell>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>
                    FACTURAS
                  </TableCell>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>
                    CLIENTE
                  </TableCell>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>
                    ESTADO
                  </TableCell>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>
                    ACCIONES
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, i) => (
                  <TableRow key={i} hover role="checkbox">
                    <TableCell>
                      <span className='flex_title'>{item.payment_code}</span>
                      <span className='flex_subtitle'>{item.created_at}</span>
                      
                    </TableCell>
                    <TableCell>${item.amount.toLocaleString()}</TableCell>
                    <TableCell>
                      {item.invoices && item.invoices.length > 0 ? (
                        item.invoices.map((invoice: any, index: number) => (
                          <div key={index} className='invoice_item_container'>
                            <b>{invoice.invoice_code}</b> ${invoice.payment_amount.toLocaleString()}
                          </div>
                        ))
                      ) : (
                        'No hay facturas asociadas'
                      )}
                    </TableCell>
                    <TableCell>
                      <div className='invoice_item_container'>
                        <b>NIT</b> {item.document}
                      </div>
                      <div className='invoice_item_container'>
                        <b>R. Social</b> {item.business_name}
                      </div>
                      <div className='invoice_item_container'>
                        <b>Cliente:</b> {item.client_name}
                      </div>
                    </TableCell>
                    <TableCell>
                      {item.deleted ? <Chip variant='outlined' color="error" label="Anulado" /> : null}

                    </TableCell>

                    <TableCell align="right" style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                      {!item.deleted && (
                      <Button
                        size="small"
                        variant="contained" 
                        color="secondary"
                        onClick={() => handleVoidPayment(item.payment_id)}
                        startIcon={<RiDeleteBin6Line />}
                      >
                        Anular
                      </Button>
                      )}

                      <Button
                        size="small"
                        variant="contained" 
                        onClick={() => exportPayment(item.payment_id)}
                        startIcon={<IoCloudDownloadOutline />}
                      >
                        Comprobante
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
            style={{ marginTop: '20px', alignSelf: 'center' }}
          />
        </Box>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="¿Está seguro que desea anular este recaudo?"
        send={voidPayment}
      />
    </div>
  );
}
